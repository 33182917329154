<template>
  <div class="page-photo page">
    <Topo :back="true" :notification="true" :plans="true"/>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row>
          <v-col>
            <v-card class="card-section" outlined>
              <v-data-table :options="{ itemsPerPage: 10, }"
                            no-data-text="Nenhum produto encontrado"
                            :footer-props="{'items-per-page-options':[5,10, 20, 100, -1],'items-per-page-all-text':'Todos','items-per-page-text':'Itens por página',}"
                            :headers="headers"
                            :search="search"
                            :items="items"
                            sort-by="nome"
                            class="elevation-1">
                <template v-slot:item.situation="{ item }">
                  <v-btn
                      depressed
                      :class="item.situation.id === 'S' ? 'btn-active' : 'btn-not-active'"
                      :disabled="loading_situation  && item.id === situation_index"
                      :loading="loading_situation  && item.id === situation_index"
                      @click="situation_item(item, item.situation.id === 'S' ? 'N' : 'S'); situation_index = item.id">
                    {{ item.situation.id === 'S' ? 'Sim' : 'Não' }}
                  </v-btn>
                </template>

                <template v-slot:item.featured="{ item }">
                  <v-btn
                      depressed
                      :class="item.featured ? 'btn-active' : 'btn-not-active'"
                      :disabled="loading_featured && item.id === featured_index"
                      :loading="loading_featured && item.id === featured_index"
                      @click="featured_item(item, item.featured ? 'N' : 'S'); featured_index = item.id">
                    {{ item.featured ? 'Sim' : 'Não' }}
                  </v-btn>
                </template>

                <template v-slot:top>
                  <div class="topo-card">
                    <v-row dense>
                      <v-col md="4" cols>
                        <div class="d-flex">
                          <v-toolbar-title>Lista de galerias</v-toolbar-title>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon
                                     v-bind="attrs"
                                     v-on="on"
                                     class="ml-3"
                                     @click="dialog_info = true">
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Informação</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                      <v-col md="8" cols>
                        <div class="d-flex">
                          <v-text-field
                              v-model="search"
                              class="search-card"
                              append-icon="mdi-magnify"
                              label="Buscar..."
                              solo
                              flat
                              hide-details
                          ></v-text-field>
                          <v-dialog v-model="dialog_add" max-width="500px" scrollable>
                            <v-card>
                              <v-card-title>
                                <span class="card-title">Cadastrar</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       v-on:click="resetForm()"
                                       @click="dialog_add = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-text-field
                                      v-model="title"
                                      :error-messages="titleErrors"
                                      label="Título"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.title.$touch()"
                                      @blur="$v.title.$touch()"
                                  ></v-text-field>
                                  <v-textarea
                                      v-model="description"
                                      label="Descrição"
                                      outlined
                                      clearable
                                      dense>
                                  </v-textarea>
                                  <v-select
                                      v-model="category"
                                      :items="categories"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="categoryErrors"
                                      label="Categorias"
                                      outlined
                                      required
                                      return-object
                                      clearable
                                      dense
                                      @input="$v.category.$touch()"
                                      @blur="$v.category.$touch()"
                                  ></v-select>

                                  <v-select
                                      v-model="situation"
                                      :items="visivel"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="situationErrors"
                                      label="Visível"
                                      outlined
                                      required
                                      return-object
                                      dense
                                      clearable
                                      @input="$v.situation.$touch()"
                                      @blur="$v.situation.$touch()"
                                  ></v-select>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="add()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_edit" max-width="500px" scrollable>
                            <v-card tile>
                              <v-card-title>
                                <span class="card-title">Editar</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       @click="dialog_edit = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-text-field
                                      v-model="title"
                                      :error-messages="titleErrors"
                                      label="Título"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.title.$touch()"
                                      @blur="$v.title.$touch()"
                                  ></v-text-field>
                                  <v-textarea
                                      v-model="description"
                                      label="Descrição"
                                      outlined
                                      clearable
                                      dense>
                                  </v-textarea>
                                  <v-select
                                      v-model="category"
                                      :items="categories"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="categoryErrors"
                                      label="Área do site"
                                      outlined
                                      required
                                      return-object
                                      dense
                                      clearable
                                      @input="$v.category.$touch()"
                                      @blur="$v.category.$touch()"
                                  ></v-select>
                                  <v-select
                                      v-model="situation"
                                      :items="visivel"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="situationErrors"
                                      label="Visível"
                                      outlined
                                      required
                                      return-object
                                      dense
                                      clearable
                                      @input="$v.situation.$touch()"
                                      @blur="$v.situation.$touch()"
                                  ></v-select>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="save_item()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_delete" max-width="290">
                            <v-card class="card-delete">
                              <v-card-text class="text-center">
                                <v-icon>mdi-information-outline</v-icon>
                                <div>Tem certeza que deseja <strong>excluir?</strong>
                                </div>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn depressed
                                       @click="dialog_delete = false">
                                  Não
                                </v-btn>

                                <v-btn depressed :loading="loading" :disabled="loading"
                                       @click="excluir()">
                                  Sim
                                </v-btn>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_upload" max-width="1185px" scrollable>
                            <v-card class="card-upload">
                              <v-card-title>
                                <span class="card-title">Upload de arquivo</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       @click="dialog_upload = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <v-file-input
                                    v-model="file"
                                    color="#263C4D"
                                    accept="image/png, image/jpeg, image/bmp, image/gif"
                                    dense
                                    counter
                                    multiple
                                    show-size
                                    small-chips
                                    truncate-length="13"
                                    label="Arquivo"
                                    placeholder="Selecione seu arquivo"
                                    prepend-icon="mdi-upload"
                                    outlined>
                                </v-file-input>
                                <v-row v-if="item.files">
                                  <v-col md="3" sm="4" cols
                                         v-for="(files, i) in item.files" :key="i">
                                    <v-card outlined>
                                      <v-img height="180"
                                             :src="files.img"></v-img>
                                      <v-card-text>
                                        <div class="information">
                                          <div class="date">Data:
                                            {{ files.date }}
                                          </div>
                                          <div class="size">Original:
                                            {{ formatBytes(files.size) }}
                                          </div>
                                          <div class="site-compressed">
                                            Comprimido:
                                            {{ formatBytes(files.compressed) }}
                                          </div>
                                          <v-text-field
                                              class="mt-3"
                                              v-model="description_photo[i]"
                                              label="Descrição"
                                              outlined
                                              clearable
                                              dense
                                              hide-details
                                              @change="description_file(files, item)"
                                          ></v-text-field>
                                        </div>
                                      </v-card-text>
                                      <v-card-actions class="card-actions-upload">
                                        <v-tooltip bottom>
                                          <template
                                              v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs"
                                                   v-on="on"
                                                   depressed
                                                   class="btn-delete-file mr-2"
                                                   :disabled="loading_delete && i === loading_index"
                                                   :loading="loading_delete && i === loading_index"
                                                   @click="delete_file(files, item); loading_index = i">
                                              <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Excluir arquivo</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                          <template
                                              v-slot:activator="{ on, attrs }">
                                            <v-btn depressed
                                                   v-bind="attrs"
                                                   v-on="on"
                                                   :class="files.cover ? 'btn-capa-active' : 'btn-capa-img'"
                                                   :disabled="loading_cover && i === loading_index"
                                                   :loading="loading_cover && i === loading_index"
                                                   @click="cover_photo(files, item); loading_index = i">
                                              <v-icon v-if="files.cover">
                                                mdi-image-outline
                                              </v-icon>
                                              <v-icon v-else>
                                                mdi-image-off-outline
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <span v-if="files.cover">Capa cadastrada</span>
                                          <span v-else>Cadastrar capa</span>
                                        </v-tooltip>
                                      </v-card-actions>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="file.length <= 0"
                                    @click="submit_file()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn dark
                                     depressed
                                     class="btn-add-category"
                                     v-bind="attrs"
                                     v-on="on"
                                     to="/categoriasfotos">
                                <v-icon>mdi-filter-plus-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Cadastrar categorias</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn dark depressed class="btn-add" v-bind="attrs"
                                     v-on="on"
                                     @click="resetForm(); dialog_add = true">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Cadastrar</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs" v-on="on"
                                  depressed
                                  color="primary"
                                  class="btn-refresh"
                                  :loading="loading_refresh"
                                  :disabled="loading_refresh"
                                  @click="initialize">
                                <v-icon>mdi-refresh</v-icon>
                              </v-btn>
                            </template>
                            <span>Recarregar</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-menu class="menu-list" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link @click="edit(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-image-edit-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item link @click="deleteItem(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Excluir</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item link @click="upload(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-upload</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Upload de arquivo</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:no-data>
                  <v-btn :loading="loading_refresh"
                         :disabled="loading_refresh"
                         color="primary" depressed @click="initialize">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-dialog v-model="dialog_info" max-width="800px" scrollable>
      <v-card>
        <v-card-title>
          <span class="card-title">Informação</span>
          <v-spacer></v-spacer>
          <v-btn icon color="blue darken-1"
                 v-on:click="resetForm()"
                 @click="dialog_info = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <Footer/>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, minLength} from 'vuelidate/lib/validators';
import Topo from "../../components/Topo";
import Footer from "../../components/Footer";
import {formatBytes} from "@/utils.js";

export default {
  name: "Fotos",
  components: {Topo, Footer},
  mixins: [validationMixin],
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Título',
        align: 'start',
        value: 'title',
      },
      {text: 'Data de cadastro', value: 'date'},
      {text: 'Categoria', value: 'category.description'},
      {text: 'Visível', value: 'situation', sortable: false},
      {text: 'Destaque', value: 'featured', sortable: false},
      {text: 'Ação', value: 'actions', sortable: false},
    ],
    items: [],
    categories: [],
    visivel: [{id: "S", description: "Sim"}, {id: "N", description: "Não"}],
    loading: false,
    loading_file: false,
    loading_cover: false,
    loading_delete: false,
    loading_featured: false,
    loading_situation: false,
    loading_refresh: false,
    loading_index: -1,
    situation_index: -1,
    featured_index: -1,
    dialog_add: false,
    dialog_edit: false,
    dialog_delete: false,
    dialog_upload: false,
    dialog_info: false,
    show: false,
    snackbar: {
      show: false,
      text: "",
      timeout: 7000,
    },
    item: {},
    index: -1,
    title: '',
    date: '',
    category: '',
    description: '',
    situation: '',
    featured: '',
    description_photo: [],
    file: [],
  }),
  validations: {
    title: {
      required,
      minLength: minLength(5)
    },

    category: {
      required,
    },

    situation: {
      required
    },
  },
  async created() {
    await this.initialize();
    await this.search_category();
  },
  computed: {
    titleErrors() {
      const errors = []
      if (!this.$v.title.$dirty) return errors
      !this.$v.title.minLength && errors.push('O título deve ter no mínino 5 caracteres.')
      !this.$v.title.required && errors.push('Título é obrigatório.')
      return errors
    },
    categoryErrors() {
      const errors = []
      if (!this.$v.category.$dirty) return errors
      !this.$v.category.required && errors.push('Categoria é obrigatório.')
      return errors
    },
    situationErrors() {
      const errors = []
      if (!this.$v.situation.$dirty) return errors
      !this.$v.situation.required && errors.push('Situação é obrigatório.')
      return errors
    },
  },
  methods: {
    formatBytes,

    async initialize() {
      this.loading_refresh = true;
      await this.Api().post('/json/photo_list', {
        'token': this.$store.state.token,
      }).then(response => {
        if (response.data.status === "invalid") {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          this.$router.push({path: "/login",}).catch(err => {
            console.log(err)
          });
        } else {
          this.items = response.data;
          this.loading_refresh = false;
        }
      }).catch(error => {
        this.loading_refresh = false;
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async add() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'title': this.title,
          'description': this.description,
          'category': this.category,
          'situation': this.situation,
        };
        this.Api().post('/json/add_photo', data).then(response => {
          const now = new Date;
          const hoje = now.getDate() + "/" + (now.getMonth() + 1) + "/" + now.getFullYear();
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;

              this.items.push({
                id: response.data.id,
                title: this.title,
                description: this.description,
                date: hoje,
                category: this.category,
                situation: this.situation
              });
              this.loading = false;
              this.dialog_add = false;
              this.resetForm();
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);
    },

    async edit(item) {
      this.index = this.items.indexOf(item);
      this.item = Object.assign({}, item);

      this.title = this.item.title;
      this.description = this.item.description;
      this.category = this.item.category;
      this.situation = this.item.situation;
      this.dialog_edit = true;
    },

    async save_item() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'item': this.item.id,
          'token': this.$store.state.token,
          'title': this.title,
          'description': this.description,
          'category': this.category,
          'situation': this.situation,
        };

        this.Api().post('/json/save_photo', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;

              Object.assign(this.items[this.index], {
                title: this.title,
                description: this.description,
                date: this.items[this.index].date,
                category: this.category,
                situation: this.situation
              });
              this.loading = false;
              this.dialog_edit = false;
              this.resetForm();
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_edit = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
        });
      }, 3000);
    },

    async deleteItem(item) {
      this.index = this.items.indexOf(item);
      this.item = Object.assign({}, item);
      this.dialog_delete = true;
    },

    async excluir() {
      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': this.item.id,
        };
        this.Api().post('/json/delete_photo', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.items.splice(this.index, 1);
              this.loading = false;
              this.dialog_delete = false;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_delete = false;
              break;
          }

        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);

    },

    async upload(item) {
      this.index = this.items.indexOf(item);
      this.item = Object.assign({}, item);

      if (this.items[this.index].files) {
        this.items[this.index].files.map(r => {
          this.description_photo.push(r.description);
        });
      }

      this.dialog_upload = true;
    },

    async submit_file() {
      this.loading = true;
      var result = true;

      if (this.file.length > process.env.VUE_APP_FILE_LIMIT) {
        this.snackbar.show = true;
        this.snackbar.text = 'Não é possível enviar mais que ' + process.env.VUE_APP_FILE_LIMIT + ' arquivos por vez.';
        this.loading = false;
        result = false;
      }

      for (var a = 0; a < this.file.length; a++) {
        var size = this.file[a].size;

        if ((size / 1024) > process.env.VUE_APP_SIZE_LIMIT) {
          this.snackbar.show = true;
          this.snackbar.text = 'Não é possível enviar um arquivo com tamanho superior a ' + formatBytes(process.env.VUE_APP_SIZE_LIMIT * 1024);
          this.loading = false;
          result = false;
        }
      }

      if (result) {
        let formData = new FormData();
        for (var i = 0; i < this.file.length; i++) {
          let file = this.file[i];
          formData.append('files[' + i + ']', file);
        }

        formData.append('token', this.$store.state.token);
        formData.append('item', this.item.id);

        setTimeout(() => {
          this.Api().post('/json/send_photo_file', formData).then(response => {

            switch (response.data.status) {
              case "invalid":
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                this.$router.push({path: "/login",}).catch(err => {
                  console.log(err)
                });
                break;

              case "success":

                this.$set(this.items[this.index], 'files', response.data.files);
                this.$set(this.item, 'files', response.data.files);

                this.loading = false;
                this.snackbar.show = true;
                this.snackbar.text = response.data.text;
                this.resetForm();
                break;

              default:
                this.snackbar.show = true;
                this.snackbar.text = response.data.text;
                this.loading = false;
                this.resetForm();
                break;
            }

            if (response.data.status === "success") {
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.resetForm();
            } else {
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.resetForm();
            }
          }).catch(error => {
            this.snackbar.show = true;
            this.snackbar.text = error;
            this.loading = false;
            this.resetForm();
          });
        }, 3000);
      }
    },

    async delete_file(file, item) {
      this.loading_delete = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': file.id
        };
        this.Api().post('/json/delete_photo_file', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.items.findIndex(r => item.id === r.id);
              this.items[this.index].files = this.items[this.index].files.filter(r => {
                return r.id !== file.id;
              });
              this.item.files = this.item.files.filter(r => {
                return r.id !== file.id;
              })

              this.$set(this.items[this.index]);
              this.$set(this.item);

              this.loading_delete = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;

              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_delete = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_delete = false;
        });
      }, 1000);

    },

    async cover_photo(file, item) {
      this.loading_cover = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': file.id,
          'galeria': item.id
        };
        this.Api().post('/json/cover_photo', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.items.findIndex(r => item.id === r.id);
              var i = item.files.findIndex(r => file.id === r.id);
              this.items[this.index].files.map(r => {
                this.$set(r, 'cover', false);
              });
              this.$set(this.items[this.index].files[i], 'cover', true);
              this.$set(this.item.files[i], 'cover', true);

              this.loading_cover = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_cover = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_cover = false;
        });
      }, 1000);

    },

    async description_file(file, item) {
      const i = item.files.findIndex(r => file.id === r.id);

      const data = {
        'token': this.$store.state.token,
        'item': file.id,
        'galeria': item.id,
        'description': this.description_photo[i]
      };
      await this.Api().post('/json/description_photo_file', data).then(response => {
        switch (response.data.status) {
          case "invalid":
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.$router.push({path: "/login",}).catch(err => {
              console.log(err)
            });
            break;

          case "success":
            this.index = this.items.findIndex(r => item.id === r.id);
            this.$set(this.items[this.index].files[i], 'description', this.description_photo[i]);
            this.$set(this.item.files[i], 'description', this.description_photo[i]);

            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;

          default:
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;
        }
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
      });

    },

    async featured_item(item, r) {
      this.loading_featured = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': item.id,
          'featured': r
        };
        this.Api().post('/json/featured_photo', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.items.findIndex(r => item.id === r.id);
              this.$set(this.items[this.index], 'featured', r === 'S' ? true : false);
              this.$set(this.item, 'featured', r === 'S' ? true : false);

              this.loading_featured = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_featured = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_featured = false;
        });
      }, 1000);
    },

    async situation_item(item, r) {
      this.loading_situation = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': item.id,
          'situation': r
        };
        this.Api().post('/json/situation_photo', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.items.findIndex(r => item.id === r.id);
              this.$set(this.items[this.index].situation, 'id', r === 'S' ? 'S' : 'N');

              this.loading_situation = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_situation = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_situation = false;
        });
      }, 1000);
    },

    async search_category() {
      await this.Api().post('/json/search_category', {
        'token': this.$store.state.token,
      }).then(response => {
        this.categories = response.data;
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    resetForm() {
      this.title = '';
      this.category = '';
      this.date = '';
      this.description = '';
      this.situation = '';
      this.file = [];
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  }
}
</script>
