<template>
  <div class="home">
    <Topo :notification="true" :plans="true"/>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row>
          <v-col v-for="(item, i) in items.modules" :key="i">
            <v-card class="card-quantity" flat>
              <v-card-text>
                <div class="title-card">{{ item.title }}</div>
                <div class="value-card">{{ item.quantity }}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!--<v-row v-if="domain">
          <v-col>
            <v-card class="card-size" flat>
              <v-card-text>
                <div class="title-card">E-mail</div>
                <v-progress-linear :value="discoEmails" height="25">
                  <strong>{{ discoEmails}}%</strong>
                </v-progress-linear>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>-->
      </v-container>
    </v-main>
    <Footer/>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData} from "@/utils.js";

export default {
  name: "Home",
  components: {Topo, Footer},
  data: () => ({
    items: [],
    domain: [],
    discoEmails: 0,
    snackbar: {
      show: false,
      text: "",
      timeout: 7000,
    },
  }),
  async created() {
    await this.initialize();
    //this.discoEmails = parseInt((parseData(this.$store.state.domain).size_server.body.email.replace(',', '.').replace(' MB', '') / 1024) / parseData(this.$store.state.domain).info_server.body.discoEmails);
  },
  methods: {
    parseData,
    async initialize() {

      await this.Api().post('/json/home', {
        'token': this.$store.state.token,
      }).then(response => {
        if (response.data.status === "invalid") {
          this.$store.commit('logout');
          this.$router.replace('/login');
        } else {
          this.items = response.data;
        }
      }).catch(error => {
        this.loading_refresh = false;
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },
  },
}
</script>
<style lang="scss">
.home {
  .card-quantity {
    .v-icon {
      font-size: 24px !important;
    }

    .title-card {
      font-size: 16px;
      color: var(--cor-principal);
    }

    .value-card {
      font-size: 24px;
      font-weight: bold;
      color: var(--cor-segundaria);
    }
  }
}
</style>
