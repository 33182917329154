<template>
  <div class="page-products page">
    <Topo :back="true" :notification="true" :plans="true"/>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row>
          <v-col>
            <v-card class="card-section" outlined>
              <v-data-table :options="{ itemsPerPage: 10, }"
                            no-data-text="Nenhum produto encontrado"
                            :footer-props="{'items-per-page-options':[5,10, 20, 100, -1],'items-per-page-all-text':'Todos','items-per-page-text':'Itens por página',}"
                            :headers="headers"
                            :search="search"
                            :items="items"
                            sort-by="nome"
                            class="elevation-1">
                <template v-slot:item.situation="{ item }">
                  <v-btn
                      depressed
                      :class="item.situation.id === 'S' ? 'btn-active' : 'btn-not-active'"
                      :disabled="loading_situation  && item.id === situation_index"
                      :loading="loading_situation  && item.id === situation_index"
                      @click="situation_item(item, item.situation.id === 'S' ? 'N' : 'S'); situation_index = item.id">
                    {{ item.situation.id === 'S' ? 'Sim' : 'Não' }}
                  </v-btn>
                </template>
                <template v-slot:item.featured="{ item }">
                  <v-btn
                      depressed
                      :class="item.featured ? 'btn-active' : 'btn-not-active'"
                      :disabled="loading_featured && item.id === featured_index"
                      :loading="loading_featured && item.id === featured_index"
                      @click="featured_item(item, item.featured ? 'N' : 'S'); featured_index = item.id">
                    {{ item.featured ? 'Sim' : 'Não' }}
                  </v-btn>
                </template>
                <template v-slot:top>
                  <div class="topo-card">
                    <v-row dense>
                      <v-col md="4" cols>
                        <div class="d-flex">
                          <v-toolbar-title>Lista de produtos</v-toolbar-title>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon
                                     v-bind="attrs"
                                     v-on="on"
                                     class="ml-3"
                                     @click="dialog_info = true">
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Informação</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                      <v-col md="8" cols>
                        <div class="d-flex">
                          <v-text-field
                              v-model="search"
                              class="search-card"
                              append-icon="mdi-magnify"
                              label="Buscar..."
                              solo
                              flat
                              hide-details
                          ></v-text-field>
                          <v-dialog v-model="dialog_add" max-width="500px" scrollable>
                            <v-card>
                              <v-card-title>
                                <span class="card-title">Cadastrar</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1" v-on:click="resetForm()" @click="dialog_add = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-text-field
                                      v-model="reference"
                                      :error-messages="referenceErrors"
                                      label="Referência"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.reference.$touch()"
                                      @blur="$v.reference.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="title"
                                      :error-messages="titleErrors"
                                      label="Título"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.title.$touch()"
                                      @blur="$v.title.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="weight"
                                      label="Peso em kg"
                                      outlined
                                      clearable
                                      dense

                                  ></v-text-field>
                                  <v-text-field
                                      v-model="unity"
                                      label="Unidade"
                                      outlined
                                      clearable
                                      dense
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="brand"
                                      label="Marca"
                                      outlined
                                      clearable
                                      dense
                                  ></v-text-field>
                                  <v-select
                                      v-model="category"
                                      :items="categories"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="categoryErrors"
                                      label="Categorias"
                                      outlined
                                      required
                                      return-object
                                      clearable
                                      dense
                                      @change="search_subcategory()"
                                      @input="$v.category.$touch()"
                                      @blur="$v.category.$touch()"
                                  ></v-select>
                                  <v-select
                                      v-model="subcategory"
                                      :items="subcategories"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="subcategoryErrors"
                                      label="Subcategorias"
                                      outlined
                                      required
                                      return-object
                                      clearable
                                      dense
                                      @input="$v.subcategory.$touch()"
                                      @blur="$v.subcategory.$touch()"
                                  ></v-select>
                                  <v-textarea
                                      v-model="description"
                                      label="Descrição"
                                      outlined
                                      clearable
                                      dense
                                  ></v-textarea>
                                  <v-text-field
                                      v-model.lazy="value"
                                      v-money="money"
                                      label="Valor"
                                      outlined
                                      clearable
                                      dense

                                      ></v-text-field>
                                  <v-text-field
                                      v-model.lazy="discount"
                                      v-money="money"
                                      label="Desconto"
                                      outlined
                                      clearable
                                      dense
                                     ></v-text-field>
                                  <v-select
                                      v-model="situation"
                                      :items="visivel"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="situationErrors"
                                      label="Visível"
                                      outlined
                                      required
                                      return-object
                                      dense
                                      clearable
                                      @input="$v.situation.$touch()"
                                      @blur="$v.situation.$touch()"
                                  ></v-select>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="add()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_edit" max-width="500px" scrollable>
                            <v-card tile>
                              <v-card-title>
                                <span class="card-title">Editar</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       @click="dialog_edit = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-text-field
                                      v-model="reference"
                                      :error-messages="referenceErrors"
                                      label="Referência"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.reference.$touch()"
                                      @blur="$v.reference.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="title"
                                      :error-messages="titleErrors"
                                      label="Título"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.title.$touch()"
                                      @blur="$v.title.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="weight"
                                      label="Peso em kg"
                                      outlined
                                      clearable
                                      dense
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="unity"
                                      label="Unidade"
                                      outlined
                                      clearable
                                      dense
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="brand"
                                      label="Marca"
                                      outlined
                                      clearable
                                      dense
                                  ></v-text-field>
                                  <v-select
                                      v-model="category"
                                      :items="categories"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="categoryErrors"
                                      label="Categorias"
                                      outlined
                                      required
                                      return-object
                                      clearable
                                      dense
                                      @change="search_subcategory()"
                                      @input="$v.category.$touch()"
                                      @blur="$v.category.$touch()"
                                  ></v-select>
                                  <v-select
                                      v-model="subcategory"
                                      :items="subcategories"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="subcategoryErrors"
                                      label="Subcategorias"
                                      outlined
                                      required
                                      return-object
                                      clearable
                                      dense
                                      @input="$v.subcategory.$touch()"
                                      @blur="$v.subcategory.$touch()"
                                  ></v-select>
                                  <v-textarea
                                      v-model="description"
                                      label="Descrição"
                                      outlined
                                      clearable
                                      dense
                                  ></v-textarea>
                                  <v-text-field
                                      v-model.lazy="value"
                                      v-money="money"
                                      label="Valor"
                                      outlined
                                      clearable
                                      dense

                                  ></v-text-field>
                                  <v-text-field
                                      v-model.lazy="discount"
                                      v-money="money"
                                      label="Desconto"
                                      outlined
                                      clearable
                                      dense
                                  ></v-text-field>
                                  <v-select
                                      v-model="situation"
                                      :items="visivel"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="situationErrors"
                                      label="Visível"
                                      outlined
                                      required
                                      return-object
                                      dense
                                      clearable
                                      @input="$v.situation.$touch()"
                                      @blur="$v.situation.$touch()"
                                  ></v-select>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="save_item()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_delete" max-width="290">
                            <v-card class="card-delete">
                              <v-card-text class="text-center">
                                <v-icon>mdi-information-outline</v-icon>
                                <div>Tem certeza que deseja <strong>excluir?</strong>
                                </div>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn depressed
                                       @click="dialog_delete = false">
                                  Não
                                </v-btn>

                                <v-btn depressed :loading="loading" :disabled="loading"
                                       @click="excluir()">
                                  Sim
                                </v-btn>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_upload" max-width="1185px" scrollable>
                            <v-card class="card-upload">
                              <v-card-title>
                                <span class="card-title">Upload de arquivo</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       @click="dialog_upload = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <v-tabs v-model="tab" flat centered icons-and-text
                                        show-arrows class="mb-5">
                                  <v-tabs-slider></v-tabs-slider>

                                  <v-tab @click="submit = 1">
                                    Imagens
                                    <v-icon>mdi-image</v-icon>
                                  </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tab">
                                  <v-tab-item>
                                    <v-file-input
                                        v-model="image"
                                        :error-messages="imageErrors"
                                        color="#263C4D"
                                        accept="image/png, image/jpeg, image/bmp, image/gif"
                                        dense
                                        counter
                                        multiple
                                        show-size
                                        small-chips
                                        truncate-length="13"
                                        label="Imagens"
                                        placeholder="Selecione suas imagens"
                                        outlined
                                        @input="$v.image.$touch()"
                                        @blur="$v.image.$touch()">
                                    </v-file-input>
                                    <v-row v-if="item.image">
                                      <v-col md="3" sm="4" cols
                                             v-for="(files, i) in item.image"
                                             :key="i">
                                        <v-card outlined>
                                          <v-img height="180"
                                                 :src="files.img"></v-img>
                                          <v-card-text>
                                            <div class="information">
                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                  <div v-bind="attrs"
                                                       v-on="on"
                                                       class="original">
                                                    Arquivo: {{ files.original }}
                                                  </div>
                                                </template>
                                                <span>{{ files.original }}</span>
                                              </v-tooltip>
                                              <div class="date">Data:
                                                {{ files.date }}
                                              </div>
                                              <div class="size">Original:
                                                {{ formatBytes(files.size) }}
                                              </div>
                                              <div class="site-compressed">
                                                Otimizado:
                                                {{ formatBytes(files.compressed) }}
                                              </div>
                                              <v-text-field
                                                  class="mt-3"
                                                  v-model="description_image[i]"
                                                  label="Descrição"
                                                  outlined
                                                  clearable
                                                  dense
                                                  hide-details
                                                  @change="description_file_image(files, item)"
                                              ></v-text-field>
                                            </div>
                                          </v-card-text>
                                          <v-card-actions class="card-actions-upload">
                                            <v-tooltip bottom>
                                              <template
                                                  v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs"
                                                       v-on="on"
                                                       depressed
                                                       class="btn-delete-file mr-2"
                                                       :disabled="loading_delete && i === loading_index"
                                                       :loading="loading_delete && i === loading_index"
                                                       @click="delete_file(files, item); loading_index = i">
                                                  <v-icon>mdi-delete
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Excluir arquivo</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                              <template
                                                  v-slot:activator="{ on, attrs }">
                                                <v-btn depressed
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       :class="files.cover === 'S' ? 'btn-capa-active mr-2' : 'btn-capa-img mr-2'"
                                                       :disabled="loading_cover && i === loading_index"
                                                       :loading="loading_cover && i === loading_index"
                                                       @click="cover_image(files, item); loading_index = i">
                                                  <v-icon v-if="files.cover === 'S'">
                                                    mdi-image-outline
                                                  </v-icon>
                                                  <v-icon v-else>
                                                    mdi-image-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span v-if="files.cover === 'S'">Capa cadastrada</span>
                                              <span v-else>Cadastrar capa</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                              <template
                                                  v-slot:activator="{ on, attrs }">
                                                <v-btn class="btn-download"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       depressed
                                                       download
                                                       target="_blank"
                                                       :href="files.download">
                                                  <v-icon>
                                                    mdi-download
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Dowload</span>
                                            </v-tooltip>
                                          </v-card-actions>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-tab-item>
                                </v-tabs-items>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="submit_file()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn dark
                                     depressed
                                     class="btn-add-category"
                                     v-bind="attrs"
                                     v-on="on"
                                     to="/subcategoriasproduto">
                                <v-icon>mdi-filter-variant-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Cadastrar subcategorias</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn dark
                                     depressed
                                     class="btn-add-category"
                                     v-bind="attrs"
                                     v-on="on"
                                     to="/categoriasproduto">
                                <v-icon>mdi-filter-plus-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Cadastrar categorias</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn dark depressed class="btn-add" v-bind="attrs"
                                     v-on="on"
                                     @click="resetForm(); dialog_add = true">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Cadastrar</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs" v-on="on"
                                  depressed
                                  color="primary"
                                  class="btn-refresh"
                                  :loading="loading_refresh"
                                  :disabled="loading_refresh"
                                  @click="initialize">
                                <v-icon>mdi-refresh</v-icon>
                              </v-btn>
                            </template>
                            <span>Recarregar</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-menu class="menu-list" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link @click="edit(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-calendar-edit</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item link @click="deleteItem(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Excluir</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item link @click="upload(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-upload</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Upload de arquivo</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:no-data>
                  <v-btn :loading="loading_refresh"
                         :disabled="loading_refresh"
                         color="primary" depressed @click="initialize">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-dialog v-model="dialog_info" max-width="800px" scrollable>
      <v-card>
        <v-card-title>
          <span class="card-title">Informação</span>
          <v-spacer></v-spacer>
          <v-btn icon color="blue darken-1"
                 v-on:click="resetForm()"
                 @click="dialog_info = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <Footer/>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, minLength} from 'vuelidate/lib/validators';
import Topo from "../../components/Topo";
import Footer from "../../components/Footer";
import {formatBytes, parseDate, formatDate} from "../../utils.js";
import {VMoney} from 'v-money'

export default {
  name: "Produtos",
  components: {Topo, Footer},
  mixins: [validationMixin],
  directives: {money: VMoney},
  data: () => ({
    tab: null,
    search: '',
    headers: [
      {text: 'Referência', value: 'reference'},
      {
        text: 'Título',
        align: 'start',
        value: 'title',
      },
      {text: 'Data de cadastro', value: 'date'},
      {text: 'Valor', value: 'value'},
      {text: 'Categoria', value: 'category.description'},
      {text: 'Visível', value: 'situation', sortable: false},
      {text: 'Destaque', value: 'featured', sortable: false},
      {text: 'Ação', value: 'actions', sortable: false},
    ],
    items: [],
    categories: [],
    subcategories: [],
    visivel: [{id: "S", description: "Sim"}, {id: "N", description: "Não"}],
    loading: false,
    loading_file: false,
    loading_cover: false,
    loading_delete: false,
    loading_featured: false,
    loading_situation: false,
    loading_refresh: false,
    loading_index: -1,
    situation_index: -1,
    featured_index: -1,
    dialog_add: false,
    dialog_edit: false,
    dialog_delete: false,
    dialog_upload: false,
    dialog_info: false,
    show: false,
    snackbar: {
      show: false,
      text: "",
      timeout: 7000,
    },
    item: {},
    index: -1,
    reference: '',
    title: '',
    category: {id: 1, description: "Carnes", icone: "meat.svg"},
    subcategory: '',
    description: '',
    value: '',
    discount: '',
    unity: '',
    weight: '',
    brand: '',
    situation: '',
    featured: '',
    description_image: [],
    submit: 1,
    image: [],
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      suffix: '',
      precision: 3,
      masked: false
    }
  }),
  validations: {
    reference: {
      required,
      minLength: minLength(2)
    },
    title: {
      required,
      minLength: minLength(5)
    },
    subcategory: {
      required,
    },
    situation: {
      required,
    },
    category: {
      required,
    },
    image: {
      required
    },
  },

  async created() {
    await this.initialize();
    await this.search_category();
    await this.search_subcategory();
  },

  computed: {
    referenceErrors() {
      const errors = []
      if (!this.$v.reference.$dirty) return errors
      !this.$v.reference.minLength && errors.push('O referência deve ter no mínino 2 caracteres.')
      !this.$v.reference.required && errors.push('referência é obrigatório.')
      return errors
    },
    titleErrors() {
      const errors = []
      if (!this.$v.title.$dirty) return errors
      !this.$v.title.minLength && errors.push('O título deve ter no mínino 5 caracteres.')
      !this.$v.title.required && errors.push('Título é obrigatório.')
      return errors
    },
    categoryErrors() {
      const errors = []
      if (!this.$v.category.$dirty) return errors
      !this.$v.category.required && errors.push('Categoria é obrigatório.')
      return errors
    },
    subcategoryErrors() {
      const errors = []
      if (!this.$v.subcategory.$dirty) return errors
      !this.$v.subcategory.required && errors.push('Subcategoria é obrigatório.')
      return errors
    },
    situationErrors() {
      const errors = []
      if (!this.$v.situation.$dirty) return errors
      !this.$v.situation.required && errors.push('Situação é obrigatório.')
      return errors
    },
    imageErrors() {
      const errors = []
      if (!this.$v.image.$dirty) return errors
      !this.$v.image.required && errors.push('Imagem é obrigatória.')
      return errors
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
    },
  },

  methods: {
    formatBytes,
    parseDate,
    formatDate,

    async initialize() {
      await this.Api().post('/json/products_list', {
        'token': this.$store.state.token,
      }).then(response => {
        if (response.data.status === "invalid") {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          this.$router.push({path: "/login",}).catch(err => {
            console.log(err)
          });
        } else {
          this.items = response.data;
        }
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async add() {

      this.$v.$touch();

      if (this.$v.title.$anyError
          || this.$v.reference.$anyError
          || this.$v.situation.$anyError
          || this.$v.category.$anyError
          || this.$v.subcategory.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'reference': this.reference,
          'title': this.title,
          'description': this.description,
          'value': this.value,
          'discount': this.discount,
          'category': this.category,
          'subcategory': this.subcategory,
          'unity': this.unity,
          'weight': this.weight,
          'brand': this.brand,
          'situation': this.situation,
        };
        this.Api().post('/json/add_products', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;

              this.items.unshift({
                id: response.data.id,
                reference: this.reference,
                title: this.title,
                date: this.formatDate(this.date),
                description: this.description,
                unity: this.unity,
                weight: this.weight,
                brand: this.brand,
                value: this.value,
                discount: this.discount,
                category: this.category,
                subcategory: this.subcategory,
                situation: this.situation
              });
              this.loading = false;
              this.dialog_add = false;
              this.resetForm();
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);
    },

    async edit(item) {
      this.index = this.items.indexOf(item);
      this.item = Object.assign({}, item);

      this.reference = this.item.reference;
      this.title = this.item.title;
      this.description = this.item.description;
      this.unity = this.item.unity;
      this.weight = this.item.weight;
      this.brand = this.item.brand;
      this.value = this.item.value;

      this.discount = this.item.discount;
      this.category = this.item.category;
      await this.search_subcategory();
      this.subcategory = this.item.subcategory;
      this.situation = this.item.situation;
      this.dialog_edit = true;
    },

    async save_item() {
      this.$v.$touch();

      if (this.$v.title.$anyError
          || this.$v.reference.$anyError
          || this.$v.situation.$anyError
          || this.$v.category.$anyError
          || this.$v.subcategory.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'item': this.item.id,
          'token': this.$store.state.token,
          'reference': this.reference,
          'title': this.title,
          'description': this.description,
          'value': this.value,
          'discount': this.discount,
          'category': this.category,
          'subcategory': this.subcategory,
          'unity': this.unity,
          'weight': this.weight,
          'brand': this.brand,
          'situation': this.situation,
        };

        this.Api().post('/json/save_products', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;

              Object.assign(this.items[this.index], {
                reference: this.reference,
                title: this.title,
                date: this.formatDate(this.date),
                description: this.description,
                unity: this.unity,
                weight: this.weight,
                brand: this.brand,
                value: this.value,
                discount: this.discount,
                category: this.category,
                subcategory: this.subcategory,
                situation: this.situation
              });

              this.loading = false;
              this.dialog_edit = false;
              this.resetForm();
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_edit = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
        });
      }, 3000);
    },

    async deleteItem(item) {
      this.index = this.items.indexOf(item);
      this.item = Object.assign({}, item);
      this.dialog_delete = true;
    },

    async excluir() {
      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': this.item.id,
        };
        this.Api().post('/json/delete_products', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.items.splice(this.index, 1);
              this.loading = false;
              this.dialog_delete = false;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_delete = false;
              break;
          }

        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);

    },

    async upload(item) {
      this.index = this.items.indexOf(item);
      this.item = Object.assign({}, item);

      if (this.items[this.index].image) {
        this.items[this.index].image.map(r => {
          this.description_image.push(r.description);
        });
      }
      this.dialog_upload = true;
    },

    async submit_file() {
      switch (this.submit) {
        case 1:
          this.upload_image();
          break;
      }
    },

    async upload_image() {
      this.$v.$touch();

      if (this.$v.image.$anyError) {
        return;
      }

      this.loading = true;
      var result = true;

      if (this.image.length > process.env.VUE_APP_FILE_LIMIT) {
        this.snackbar.show = true;
        this.snackbar.text = 'Não é possível enviar mais que ' + process.env.VUE_APP_FILE_LIMIT + ' arquivos por vez.';
        this.loading = false;
        result = false;
      }

      for (var a = 0; a < this.image.length; a++) {
        var size = this.image[a].size;

        if ((size / 1024) > process.env.VUE_APP_SIZE_LIMIT) {
          this.snackbar.show = true;
          this.snackbar.text = 'Não é possível enviar um arquivo com tamanho superior a ' + formatBytes(process.env.VUE_APP_SIZE_LIMIT * 1024);
          this.loading = false;
          result = false;
        }
      }

      if (result) {
        let formData = new FormData();
        for (var i = 0; i < this.image.length; i++) {
          let file = this.image[i];
          formData.append('files[' + i + ']', file);
        }

        formData.append('token', this.$store.state.token);
        formData.append('item', this.item.id);

        setTimeout(() => {
          this.Api().post('/json/send_image_products', formData).then(response => {

            switch (response.data.status) {
              case "invalid":
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                this.$router.push({path: "/login",}).catch(err => {
                  console.log(err)
                });
                break;

              case "success":

                this.$set(this.items[this.index], 'image', response.data.image);
                this.$set(this.item, 'image', response.data.image);

                this.loading = false;
                this.snackbar.show = true;
                this.snackbar.text = response.data.text;
                this.resetForm();
                break;

              default:
                this.snackbar.show = true;
                this.snackbar.text = response.data.text;
                this.loading = false;
                this.resetForm();
                break;
            }

          }).catch(error => {
            this.snackbar.show = true;
            this.snackbar.text = error;
            this.loading = false;
            this.resetForm();
          });
        }, 3000);
      }
    },

    async delete_file(file, item) {
      this.loading_delete = true;

      switch (this.submit) {
        case 1:
          setTimeout(() => {
            const data = {
              'token': this.$store.state.token,
              'item': file.id
            };
            this.Api().post('/json/delete_image_products', data).then(response => {
              switch (response.data.status) {
                case "invalid":
                  localStorage.removeItem('token');
                  localStorage.removeItem('user');
                  this.$router.push({path: "/login",}).catch(err => {
                    console.log(err)
                  });
                  break;

                case "success":
                  this.index = this.items.findIndex(r => item.id === r.id);
                  this.items[this.index].image = this.items[this.index].image.filter(r => {
                    return r.id !== file.id;
                  });
                  this.item.image = this.item.image.filter(r => {
                    return r.id !== file.id;
                  })

                  this.$set(this.items[this.index]);
                  this.$set(this.item);

                  this.loading_delete = false;
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;

                  break;

                default:
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;
                  this.loading_delete = false;
                  break;
              }
            }).catch(error => {
              this.snackbar.show = true;
              this.snackbar.text = error;
              this.loading_delete = false;
            });
          }, 1000);
          break;
        case 2:
          setTimeout(() => {
            const data = {
              'token': this.$store.state.token,
              'item': file.id
            };
            this.Api().post('/json/delete_video_products', data).then(response => {
              switch (response.data.status) {
                case "invalid":
                  localStorage.removeItem('token');
                  localStorage.removeItem('user');
                  this.$router.push({path: "/login",}).catch(err => {
                    console.log(err)
                  });
                  break;

                case "success":
                  this.index = this.items.findIndex(r => item.id === r.id);
                  this.items[this.index].video = this.items[this.index].video.filter(r => {
                    return r.id !== file.id;
                  });
                  this.item.video = this.item.video.filter(r => {
                    return r.id !== file.id;
                  })

                  this.$set(this.items[this.index]);
                  this.$set(this.item);

                  this.loading_delete = false;
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;

                  break;

                default:
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;
                  this.loading_delete = false;
                  break;
              }
            }).catch(error => {
              this.snackbar.show = true;
              this.snackbar.text = error;
              this.loading_delete = false;
            });
          }, 1000);
          break;
        case 3:
          setTimeout(() => {
            const data = {
              'token': this.$store.state.token,
              'item': file.id
            };
            this.Api().post('/json/delete_audio_products', data).then(response => {
              switch (response.data.status) {
                case "invalid":
                  localStorage.removeItem('token');
                  localStorage.removeItem('user');
                  this.$router.push({path: "/login",}).catch(err => {
                    console.log(err)
                  });
                  break;

                case "success":
                  this.index = this.items.findIndex(r => item.id === r.id);
                  this.items[this.index].audio = this.items[this.index].audio.filter(r => {
                    return r.id !== file.id;
                  });
                  this.item.audio = this.item.audio.filter(r => {
                    return r.id !== file.id;
                  })

                  this.$set(this.items[this.index]);
                  this.$set(this.item);

                  this.loading_delete = false;
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;

                  break;

                default:
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;
                  this.loading_delete = false;
                  break;
              }
            }).catch(error => {
              this.snackbar.show = true;
              this.snackbar.text = error;
              this.loading_delete = false;
            });
          }, 1000);
          break;
      }
    },

    async cover_image(file, item) {
      this.loading_cover = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': file.id,
          'products': item.id
        };

        this.Api().post('/json/cover_image_products', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.items.findIndex(r => item.id === r.id);
              var i = item.image.findIndex(r => file.id === r.id);
              this.items[this.index].image.map(r => {
                this.$set(r, 'cover', 'N');
              });
              this.$set(this.items[this.index].image[i], 'cover', 'S');
              this.$set(this.item.image[i], 'cover', 'S');

              this.loading_cover = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_cover = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_cover = false;
        });
      }, 1000);

    },

    async description_file_image(file, item) {
      const i = item.image.findIndex(r => file.id === r.id);

      const data = {
        'token': this.$store.state.token,
        'item': file.id,
        'products': item.id,
        'description': this.description_image[i]
      };
      await this.Api().post('/json/description_image_products', data).then(response => {
        switch (response.data.status) {
          case "invalid":
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.$router.push({path: "/login",}).catch(err => {
              console.log(err)
            });
            break;

          case "success":
            this.index = this.items.findIndex(r => item.id === r.id);
            this.$set(this.items[this.index].image[i], 'description', this.description_image[i]);
            this.$set(this.item.image[i], 'description', this.description_image[i]);

            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;

          default:
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;
        }
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
      });

    },

    async featured_item(item, r) {
      this.loading_featured = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': item.id,
          'featured': r
        };
        this.Api().post('/json/featured_products', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.items.findIndex(r => item.id === r.id);
              this.$set(this.items[this.index], 'featured', r === 'S' ? true : false);
              this.$set(this.item, 'featured', r === 'S' ? true : false);

              this.loading_featured = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_featured = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_featured = false;
        });
      }, 1000);
    },

    async situation_item(item, r) {
      this.loading_situation = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': item.id,
          'situation': r
        };
        this.Api().post('/json/situation_products', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.items.findIndex(r => item.id === r.id);
              this.$set(this.items[this.index].situation, 'id', r === 'S' ? 'S' : 'N');

              this.loading_situation = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_situation = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_situation = false;
        });
      }, 1000);
    },

    async search_category() {
      await this.Api().post('/json/search_products_categories', {
        'token': this.$store.state.token,
      }).then(response => {
        this.categories = response.data;
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async search_subcategory() {
      await this.Api().post('/json/search_products_subcategories', {
        'token': this.$store.state.token,
        'category': this.category,
      }).then(response => {
        this.subcategories = response.data;
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    resetForm() {
      this.reference = '';
      this.title = '';
      this.description = '';
      this.value = '';
      this.discount = '';
      this.unity = '';
      this.weight = '';
      this.brand = '';
      this.category = '';
      this.subcategory = '';
      this.situation = '';
      this.image = [];
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  }
}
</script>
