<template>
    <div class="page-activate">
        <v-main>
            <div class="text-center">
                <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
            </div>
        </v-main>
        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <Footer/>
    </div>
</template>

<script>
    import Footer from "../../components/Footer";
    import {load} from 'recaptcha-v3';

    export default {
        name: "Activate",
        components: {Footer},
        data: () => ({
            siteKey: process.env.VUE_APP_RECAPTCHA,
            overlay: false,
            snackbar: {
                show: false,
                text: "",
                timeout: 7000,
            },
        }),
        created() {
            this.overlay = true;
            this.initialize(this.$route.params.key);
        },
        methods: {
            async initialize(key) {

                const recaptcha = await load(this.siteKey, {useRecaptchaNet: true, autoHideBadge: true});
                const token = await recaptcha.execute('login');

                setTimeout(() => {
                    this.Api().post('/json/activate_user', {
                        'g-recaptcha-response': token,
                        'token': this.$store.state.token,
                        'key': key,
                    }).then(response => {

                        if (response.data.status === "success") {
                            this.snackbar.show = true;
                            this.snackbar.text = response.data.text;
                            setTimeout(() => {
                                this.$router.push({
                                    path: "/",
                                }).catch(err => {
                                    console.log(err)
                                });
                            }, 1000);
                        } else {
                            this.snackbar.show = true;
                            this.snackbar.text = response.data.text;
                            this.overlay = false;

                            setTimeout(() => {
                                this.$router.push({
                                    path: "/seguranca",
                                }).catch(err => {
                                    console.log(err)
                                });
                            }, 1000);
                        }
                    }).catch(error => {
                        this.snackbar.show = true;
                        this.snackbar.text = error;
                        this.overlay = false;

                        setTimeout(() => {
                            this.$router.push({
                                path: "/seguranca",
                            }).catch(err => {
                                console.log(err)
                            });
                        }, 1000);
                    });
                }, 3000);
            },
        },
    }
</script>

<style lang="scss">
    .page-activate {
        height: 100%;
        background-color: var(--cor-principal);
        .card-section {

            .btn-change {
                text-transform: none !important;
                background-color: var(--cor-segundaria) !important;
            }
        }
    }

</style>


