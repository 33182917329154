import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

import Usuarios from '../views/settings/Usuarios.vue';
import Empresa from '../views/settings/Empresa.vue';
import NotificacaoSistema from '../views/settings/Notificacao.vue';

import Seguranca from '../views/user/Seguranca.vue';
import Atividades from '../views/user/Atividades.vue';
import Perfil from '../views/user/Perfil.vue';
import Activate from '../views/user/Activate.vue';

import Recoverypass from '../views/Recoverypass.vue';
import Login from '../views/Login.vue';
import Esqueceusenha from '../views/Esqueceusenha.vue';

import Banners from '../views/multimedia/Banners.vue';

import Apoiadores from '../views/sponsors/Apoiadores.vue';

import Fotos from '../views/multimedia/Fotos.vue';
import CategoriasFotos from '../views/multimedia/CategoriasFotos.vue';

import Videos from '../views/multimedia/Videos.vue';
import CategoriasVideos from '../views/multimedia/CategoriasVideos.vue';

import Noticias from '../views/news/Noticias.vue';
import CategoriasNoticia from '../views/news/CategoriasNoticia.vue';

import Agendas from '../views/event/Agendas.vue';
import CategoriasAgenda from '../views/event/CategoriasAgenda.vue';

import Depoimentos from '../views/testimony/Depoimentos.vue';

import Produtos from '../views/catalog/Produtos.vue';
import CategoriasProduto from '../views/catalog/CategoriasProduto.vue';
import SubCategoriasProduto from '../views/catalog/SubCategoriasProduto.vue';

import Arquivos from '../views/file/Arquivos.vue';
import CategoriasArquivo from '../views/file/CategoriasArquivo.vue';
import SubCategoriasArquivo from '../views/file/SubCategoriasArquivo.vue';

import Representantes from '../views/representative/Representantes.vue';
import Parceiros from '../views/partners/Parceiros.vue';
import Setores from '../views/departments/Departamentos.vue';

import Jogadores from '../views/players/Jogadores.vue';
import CategoriasJogadores from '../views/players/CategoriasJogadores.vue';
import ProfissoesJogadores from '../views/players/ProfissoesJogadores.vue';

import Store from '../store/index'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
    if (Store.state.token) {
        next()
        return
    }
    next('/login')
}

const ifNotAuthenticated = (to, from, next) => {
    if (!Store.state.token) {
        next()
        return
    }
    next('/')
}

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/usuarios',
        name: 'Usuários',
        component: Usuarios,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/empresa',
        name: 'Empresa',
        component: Empresa,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/notificacao-sistema',
        name: 'Notificação',
        component: NotificacaoSistema,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/seguranca',
        name: 'Seguranca',
        component: Seguranca,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/banners',
        name: 'Banners',
        component: Banners,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/fotos',
        name: 'Fotos',
        component: Fotos,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/categoriasfotos',
        name: 'Categoria Fotos',
        component: CategoriasFotos,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/videos',
        name: 'Videos',
        component: Videos,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/categoriasvideos',
        name: 'Categorias Videos',
        component: CategoriasVideos,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/noticias',
        name: 'Noticias',
        component: Noticias,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/categoriasnoticia',
        name: 'Categorias Notícia',
        component: CategoriasNoticia,
        beforeEnter: ifAuthenticated,
    },

    {
        path: '/agendas',
        name: 'Agendas',
        component: Agendas,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/categoriasagenda',
        name: 'Categorias Agenda',
        component: CategoriasAgenda,
        beforeEnter: ifAuthenticated,
    },

    {
        path: '/depoimentos',
        name: 'Depoimentos',
        component: Depoimentos,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/arquivos',
        name: 'Arquivos',
        component: Arquivos,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/categoriasarquivo',
        name: 'Categorias Arquivo',
        component: CategoriasArquivo,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/subcategoriasarquivo',
        name: 'Subcategorias Arquivo',
        component: SubCategoriasArquivo,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/produtos',
        name: 'Produtos',
        component: Produtos,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/categoriasproduto',
        name: 'Categorias Produto',
        component: CategoriasProduto,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/subcategoriasproduto',
        name: 'Subcategorias Produto',
        component: SubCategoriasProduto,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/apoiadores',
        name: 'Apoiadores',
        component: Apoiadores,
        beforeEnter: ifAuthenticated,
    },

    {
        path: '/activate/:key',
        name: 'Activate',
        component: Activate,
        //beforeEnter: ifAuthenticated,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/esqueceu-senha',
        name: 'Esqueceu Senha',
        component: Esqueceusenha,
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/recoverypass/:key',
        name: 'Recuperar Senha',
        component: Recoverypass,
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/perfil/:id',
        name: 'Perfil',
        component: Perfil,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/registro/:id',
        name: 'Registro de Atividades',
        component: Atividades,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/representantes',
        name: 'Representantes',
        component: Representantes,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/parceiros',
        name: 'Parceiros',
        component: Parceiros,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/setores',
        name: 'Setores',
        component: Setores,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/elenco',
        name: 'Elenco',
        component: Jogadores,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/categoriaselenco',
        name: 'Elenco Categoria',
        component: CategoriasJogadores,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/profissoeselenco',
        name: 'Elenco Profissoes',
        component: ProfissoesJogadores,
        beforeEnter: ifAuthenticated,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
