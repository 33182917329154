<template>
  <div class="page-company page">
    <Topo :back="true" :notification="true" :plans="true"/>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row>
          <v-col>
            <v-card class="card-section" outlined>
              <div class="topo-card">
                <v-row dense>
                  <v-col cols>
                    <v-toolbar-title>Empresa</v-toolbar-title>
                  </v-col>
                </v-row>
              </div>
              <v-card-text>
                <v-tabs class="tab-company" v-model="tab" dark show-arrows>
                  <v-tab>Empresa</v-tab>
                  <v-tab>Endereço</v-tab>
                  <v-tab>Site</v-tab>
                  <v-tab>Contato</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <v-form>
                          <v-text-field
                              v-model="subname"
                              :error-messages="subnameErrors"
                              label="Razão social"
                              required
                              outlined
                              clearable
                              dense
                              @input="$v.subname.$touch()"
                              @blur="$v.subname.$touch()"
                          ></v-text-field>
                          <v-text-field
                              v-model="name"
                              :error-messages="nameErrors"
                              label="Nome fantasia"
                              required
                              outlined
                              clearable
                              dense
                              @input="$v.name.$touch()"
                              @blur="$v.name.$touch()"
                          ></v-text-field>
                          <v-text-field
                              v-model="cnpj"
                              label="Razão social"
                              required
                              outlined
                              dense
                              disabled
                          ></v-text-field>
                          <v-textarea
                              v-model="description"
                              outlined
                              dense
                              clearable
                              label="Descrição"
                          ></v-textarea>
                          <v-btn
                              depressed
                              class="btn-submit"
                              :loading="loading"
                              :disabled="loading"
                              @click="save_company()">
                            Salvar
                          </v-btn>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <v-form>
                          <v-row dense>
                            <v-col md="8" sm="8" cols>
                              <v-text-field
                                  v-model="address"
                                  :error-messages="addressErrors"
                                  label="Endereço"
                                  required
                                  outlined
                                  clearable
                                  dense
                                  @input="$v.address.$touch()"
                                  @blur="$v.address.$touch()"
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" sm="4" cols>
                              <v-text-field
                                  v-model="number"
                                  label="Número"
                                  outlined
                                  clearable
                                  dense
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col md="4" sm="4" cols>
                              <v-text-field
                                  v-model="neighborhood"
                                  label="Bairro"
                                  outlined
                                  clearable
                                  dense
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" sm="4" cols>
                              <v-text-field
                                  v-model="complement"
                                  label="Complemento"
                                  outlined
                                  clearable
                                  dense
                              ></v-text-field>
                            </v-col>
                            <v-col md="4" sm="4" cols>
                              <v-text-field
                                  v-model="cep"
                                  v-mask="['#####-###']"
                                  label="CEP"
                                  outlined
                                  clearable
                                  dense
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col md="6" sm="6" cols>
                              <v-select
                                  v-model="state"
                                  :items="states"
                                  item-text="name"
                                  item-value="id"
                                  @change="search_city(state.id)"
                                  :error-messages="stateErrors"
                                  label="Estados"
                                  outlined
                                  required
                                  return-object
                                  clearable
                                  dense
                                  @input="$v.state.$touch()"
                                  @blur="$v.state.$touch()"
                              ></v-select>
                            </v-col>
                            <v-col md="6" sm="6" cols>
                              <v-select
                                  v-model="city"
                                  :items="cities"
                                  item-text="name"
                                  item-value="id"
                                  :error-messages="cityErrors"
                                  label="Cidades"
                                  outlined
                                  required
                                  return-object
                                  clearable
                                  dense
                                  @input="$v.city.$touch()"
                                  @blur="$v.city.$touch()"
                              ></v-select>
                            </v-col>
                          </v-row>

                          <v-btn
                              depressed
                              class="btn-submit"
                              :loading="loading"
                              :disabled="loading"
                              @click="save_address()">
                            Salvar
                          </v-btn>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <v-form>
                          <v-text-field
                              v-model="keywords"
                              label="Palavras chaves"
                              outlined
                              clearable
                              dense
                          ></v-text-field>
                          <v-text-field
                              v-model="verification"
                              label="Google site Verification"
                              outlined
                              clearable
                              dense
                          ></v-text-field>
                          <v-text-field
                              v-model="analytics"
                              label="Google Analytics"
                              outlined
                              clearable
                              dense
                          ></v-text-field>
                          <v-text-field
                              v-model="emailform"
                              :error-messages="emailformErrors"
                              @input="$v.emailform.$touch()"
                              @blur="$v.emailform.$touch()"
                              label="E-mail destino formulários"
                              required
                              outlined
                              clearable
                              dense
                          ></v-text-field>
                          <v-btn
                              depressed
                              class="btn-submit"
                              :loading="loading"
                              :disabled="loading"
                              @click="save_config()">
                            Salvar
                          </v-btn>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <v-form>
                          <v-text-field
                              v-model="facebook"
                              label="Facebook"
                              outlined
                              clearable
                              dense
                          ></v-text-field>
                          <v-text-field
                              v-model="instagram"
                              label="Instagram"
                              outlined
                              clearable
                              dense
                          ></v-text-field>
                          <v-text-field
                              v-model="linkedin"
                              label="Linkedin"
                              outlined
                              clearable
                              dense
                          ></v-text-field>
                          <v-text-field
                              v-model="whatsapp"
                              label="WhatsApp"
                              v-mask="['(##) ####-####', '(##) #####-####']"
                              outlined
                              clearable
                              dense
                          ></v-text-field>
                          <v-text-field
                              v-model="phone"
                              :error-messages="phoneErrors"
                              @input="$v.phone.$touch()"
                              @blur="$v.phone.$touch()"
                              v-mask="['(##) ####-####', '(##) #####-####']"
                              required
                              label="Telefone"
                              outlined
                              clearable
                              dense
                          ></v-text-field>
                          <v-text-field
                              v-model="email"
                              :error-messages="emailErrors"
                              @input="$v.email.$touch()"
                              @blur="$v.email.$touch()"
                              required
                              label="E-mail"
                              outlined
                              clearable
                              dense
                          ></v-text-field>
                          <v-btn
                              depressed
                              class="btn-submit"
                              :loading="loading"
                              :disabled="loading"
                              @click="save_contact()">
                            Salvar
                          </v-btn>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <Footer/>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, minLength, maxLength, email} from 'vuelidate/lib/validators';
import {mask} from "vue-the-mask";

import Topo from "../../components/Topo";
import Footer from "../../components/Footer";

export default {
  name: "Empresa",
  components: {Topo, Footer},
  mixins: [validationMixin],
  directives: {mask},
  data: () => ({
    tab: null,
    loading: false,
    snackbar: {
      show: false,
      text: "",
      timeout: 7000,
    },
    name: '',
    subname: '',
    cnpj: '',
    description: '',
    address: '',
    cep: '',
    neighborhood: '',
    complement: '',
    number: '',
    state: '',
    city: '',
    latitude: '',
    longitude: '',
    email: '',
    phone: '',
    linkedin: '',
    whatsapp: '',
    instagram: '',
    facebook: '',
    emailform: '',
    keywords: '',
    verification: '',
    analytics: '',
    states: [],
    cities: [],
  }),
  validations: {
    name: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(100),
    },
    subname: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(100),
    },
    address: {
      required,
      minLength: minLength(5)
    },

    phone: {
      required,
      minLength: minLength(13),
      maxLength: maxLength(15)
    },

    email: {
      required, email,
      maxLength: maxLength(100)
    },

    emailform: {
      required, email,
      maxLength: maxLength(100)
    },

    state: {
      required
    },
    city: {
      required
    },
  },
  async created() {
    await this.initialize();
    await this.search_state();
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.minLength && errors.push('O nome fantasia deve ter no mínino 5 caracteres.')
      !this.$v.name.maxLength && errors.push('O nome fantasia deve ter no máximo 100 caracteres.')
      !this.$v.name.required && errors.push('Nome fantasia é obrigatório.')
      return errors
    },
    subnameErrors() {
      const errors = []
      if (!this.$v.subname.$dirty) return errors
      !this.$v.subname.minLength && errors.push('A razão social deve ter no mínino 5 caracteres.')
      !this.$v.subname.maxLength && errors.push('A razão social deve ter no máximo 100 caracteres.')
      !this.$v.subname.required && errors.push('Razão social fantasia é obrigatório.')
      return errors
    },
    addressErrors() {
      const errors = []
      if (!this.$v.address.$dirty) return errors
      !this.$v.address.minLength && errors.push('O endereço deve ter no mínino 5 caracteres.')
      !this.$v.address.required && errors.push('Endereço é obrigatório.')
      return errors
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push('Estado é obrigatório.');
      return errors
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push('Cidade é obrigatório.');
      return errors
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.minLength && errors.push('O telefone deve ter no mínino 8 caracteres.');
      !this.$v.phone.maxLength && errors.push('O telefone deve ter no máximo 11 caracteres.');
      !this.$v.phone.required && errors.push('Telefone é obrigatório.');
      return errors
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Deve ser um e-mail válido.');
      !this.$v.email.maxLength && errors.push('O nome deve ter no máximo 100 caracteres.');
      !this.$v.email.required && errors.push('E-mail é obrigatório.');
      return errors
    },

    emailformErrors() {
      const errors = [];
      if (!this.$v.emailform.$dirty) return errors;
      !this.$v.emailform.email && errors.push('Deve ser um e-mail válido.');
      !this.$v.emailform.maxLength && errors.push('O nome deve ter no máximo 100 caracteres.');
      !this.$v.emailform.required && errors.push('E-mail é obrigatório.');
      return errors
    },
  },

  methods: {
    async initialize() {
      await this.Api().post('/json/searcher_company', {
        'token': this.$store.state.token,
      }).then(response => {
        this.name = response.data.name;
        this.subname = response.data.subname;
        this.cnpj = response.data.cnpj;
        this.description = response.data.description;
        this.address = response.data.address.address;
        this.cep = response.data.address.cep;
        this.neighborhood = response.data.address.neighborhood;
        this.complement = response.data.address.complement;
        this.number = response.data.address.number;
        this.state = response.data.address.state;
        this.city = response.data.address.city;
        this.number = response.data.address.number;
        this.latitude = response.data.address.latitude;
        this.longitude = response.data.address.longitude;
        this.email = response.data.contact.email;
        this.phone = response.data.contact.phone;
        this.linkedin = response.data.contact.linkedin;
        this.whatsapp = response.data.contact.whatsapp;
        this.instagram = response.data.contact.instagram;
        this.facebook = response.data.contact.facebook;
        this.emailform = response.data.config[0].value;
        this.keywords = response.data.config[3].value;
        this.verification = response.data.config[8].value;
        this.analytics = response.data.config[12].value;

        this.search_city(response.data.address.state.id);
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async search_state() {
      await this.Api().post('/json/search_state', {
        'token': this.$store.state.token,
      }).then(response => {
        this.states = response.data;
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async search_city(data) {
      await this.Api().post('/json/search_city', {
        'token': this.$store.state.token,
        'state': data,
      }).then(response => {
        this.cities = response.data;
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async save_company() {

      this.$v.$touch();

      if (this.$v.name.$anyError || this.$v.subname.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'name': this.name,
          'subname': this.subname,
          'cnpj': this.cnpj,
          'description': this.description
        };
        this.Api().post('/json/save_company', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);
    },

    async save_address() {

      this.$v.$touch();

      if (this.$v.address.$anyError || this.$v.city.$anyError || this.$v.state.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'address': this.address,
          'cep': this.cep,
          'complement': this.complement,
          'neighborhood': this.neighborhood,
          'number': this.number,
          'city': this.city.id,
          'state': this.state.id,
        };
        this.Api().post('/json/save_address', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);
    },

    async save_config() {

      this.$v.$touch();

      if (this.$v.emailform.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'keywords': this.keywords,
          'verification': this.verification,
          'analytics': this.analytics,
          'emailform': this.emailform
        };
        this.Api().post('/json/save_config', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);
    },

    async save_contact() {

      this.$v.$touch();

      if (this.$v.email.$anyError || this.$v.phone.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'facebook': this.facebook,
          'linkedin': this.linkedin,
          'instagram': this.instagram,
          'whatsapp': this.whatsapp,
          'email': this.email,
          'phone': this.phone,
        };
        this.Api().post('/json/save_contact', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);
    },
  },
}
</script>

<style lang="scss">
.page-company {
  .tab-company {
    border-radius: 5px;

    .v-tabs-bar {
      background-color: var(--cor-principal) !important;
    }
  }

  .btn-submit {
    color: var(--white) !important;
    background-color: var(--cor-principal) !important;
  }
}
</style>


