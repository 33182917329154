<template>
  <div class="page-esqueceu-senha">
    <v-container>
      <v-row>
        <v-col>
          <v-card class="mx-auto pa-5" max-width="450" elevation="5">
            <v-card-text>
              <v-card-title>Esqueceu sua senha?</v-card-title>
              <div class="text-card pa-2">Nós entendemos, coisas acontecem. Basta digitar seu endereço de e-mail abaixo
                e nós lhe enviaremos um link para redefinir sua senha!
              </div>
              <v-card-actions>
                <form class="form-esqueceu-senha">
                  <v-text-field
                      v-model="email"
                      :error-messages="emailErrors"
                      label="E-mail"
                      required
                      outlined
                      @input="$v.email.$touch()"
                      @blur="$v.email.$touch()"
                  ></v-text-field>
                  <div>
                    <v-btn class="btn-submit"
                           :loading="loading"
                           :disabled="loading"
                           depressed
                           large
                           @click="submit()">
                      Enviar
                    </v-btn>
                  </div>
                  <div class="mt-2">
                    Já tem uma conta?
                    <router-link class="link" to="/login"><strong>Entrar</strong></router-link>
                  </div>
                </form>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <Footer/>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, email} from 'vuelidate/lib/validators';
import Footer from "../components/Footer";
import {load} from 'recaptcha-v3';

export default {
  components: {Footer},
  mixins: [validationMixin],

  validations: {
    email: {required, email},
  },

  data: () => ({
    siteKey: process.env.VUE_APP_RECAPTCHA,
    loading: false,
    email: "",
    show: false,
    snackbar: {
      show: false,
      text: "",
      timeout: 7000,
    },
  }),

  computed: {

    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Deve ser um e-mail válido.')
      !this.$v.email.required && errors.push('E-mail é obrigatório.')
      return errors
    },
  },

  methods: {
    async submit() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      this.loading = true;
      const recaptcha = await load(this.siteKey, {useRecaptchaNet: true, autoHideBadge: true});
      const token = await recaptcha.execute('login');

      setTimeout(() => {
        const data = {
          'email': this.email,
          'g-recaptcha-response': token,
        };
        this.Api().post('/json/change_password', data).then(response => {
          if (response.data.status === "success") {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading = false;
            this.resetForm();
          } else {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading = false;
            this.resetForm();
          }

        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.resetForm();
        });
      }, 3000);
    },

    resetForm() {
      this.email = "";
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

  },
}
</script>
<style lang="scss">


.page-esqueceu-senha {
  height: 100%;
  background-color: var(--cor-principal);

  .form-esqueceu-senha {
    width: 100%;

    .link {
      text-decoration: none;
      color: rgba(0, 0, 0, .6);
      transition: all .2s ease-in;

      &:hover, &:active, &:focus {
        color: var(--cor-segundaria);
      }
    }


    .btn-submit {
      margin-top: 10px;
      width: 100%;
      color: var(--white) !important;
      background-color: var(--cor-principal) !important;
    }
  }
}
</style>
