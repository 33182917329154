<template>
    <v-footer absolute>
        <span class="mx-auto">&copy; {{ new Date().getFullYear() }} - malubi v1.0.0</span>
    </v-footer>
</template>

<script>
    export default {}
</script>
<style lang="scss">
    .v-footer {
        color: var(--white) !important;
        font-size: 12px !important;
        background-color: var(--cor-principal) !important;
        z-index: 0!important;
    }
</style>
