<template>
  <div class="page-safety">
    <Topo :back="true" :notification="true" :plans="true"/>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row>
          <v-col>
            <v-card class="card-section" outlined>
              <div class="topo-card">
                <v-row dense>
                  <v-col cols>
                    <v-toolbar-title>Segurança da conta</v-toolbar-title>
                  </v-col>
                </v-row>
              </div>
              <v-card-text>
                <v-row class="align-center" v-if="seguranca">
                  <v-col md="6" cols>
                    <div class="data-status align-center">
                      <div class="shield">
                        <div class="active" v-if="seguranca.situation">
                          <v-icon :color="seguranca.situation ? 'green' : 'red'">
                            mdi-shield-check-outline
                          </v-icon>
                        </div>
                        <div class="inactive" v-else>
                          <v-icon :color="seguranca.situation ? 'green' : 'red'">
                            mdi-shield-alert-outline
                          </v-icon>
                        </div>
                      </div>
                      <div class="data">
                        <div class="email">E-mail: <strong>{{ seguranca.email }}</strong></div>
                        <div class="date">Data de cadastro: {{ seguranca.date }}</div>
                        <div class="status">Status:
                          <strong :class="seguranca.situation ? 'active' : 'inactive'">{{ seguranca.status }}</strong>
                        </div>
                        <v-btn class="btn-check mt-3" v-if="seguranca.situation === false" dark
                               depressed :loading="loading_check" @click="check_email()">
                          Email de verificação
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="6" cols>
                    <div class="section-subtitle">Alteração de senha</div>
                    <div class="section-text mt-3"> Escolha uma senha forte que você não esteja
                      usando em
                      nenhum outro lugar.
                      Troque sua senha a cada 6 meses para aumentar a segurança da sua conta.
                    </div>
                    <v-btn class="btn-change mt-3" depressed dark :loading="loading" @click="change_password()">
                      Alterar senha
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../../components/Topo";
import Footer from "../../components/Footer";
import {load} from 'recaptcha-v3';

export default {
  name: "Usuarios",
  components: {Topo, Footer},
  data: () => ({
    siteKey: process.env.VUE_APP_RECAPTCHA,
    seguranca: false,
    loading: false,
    loading_check: false,
    snackbar: {
      show: false,
      text: "",
      timeout: 7000,
    },
  }),
  async created() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      await this.Api().post('/json/searcher_situation', {
        'token': this.$store.state.token,
      }).then(response => {
        this.seguranca = response.data;
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async change_password() {
      this.loading = true;
      const recaptcha = await load(this.siteKey, {useRecaptchaNet: true, autoHideBadge: true});
      const token = await recaptcha.execute('login');

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'g-recaptcha-response': token,
        };
        this.Api().post('/json/change_password_user', data).then(response => {
          if (response.data.status === "success") {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading = false;
          } else {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading = false;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
        });
      }, 3000);
    },

    async check_email() {
      this.loading_check = true;
      const recaptcha = await load(this.siteKey, {useRecaptchaNet: true, autoHideBadge: true});
      const token = await recaptcha.execute('login');

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'g-recaptcha-response': token,
        };
        this.Api().post('/json/check_email', data).then(response => {
          if (response.data.status === "success") {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading_check = false;
          } else {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading_check = false;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_check = false;
        });
      }, 3000);

    }
  },
}
</script>

<style lang="scss">
.page-safety {

  .card-section {
    .section-subtitle {
      font-size: 20px;
      font-weight: bold;
    }

    .data-status {
      display: flex;

      .shield {
        margin-right: 20px;

        .active {
          .v-icon {
            font-size: 100px !important;
          }
        }

        .inactive {
          .v-icon {
            font-size: 100px !important;
          }
        }
      }

      .data {
        .btn-check {
          text-transform: none !important;
          background-color: var(--cor-principal) !important;
        }

        .active {
          color: green;
        }

        .inactive {
          color: red;
        }
      }

    }


    .btn-change {
      text-transform: none !important;
      background-color: var(--cor-segundaria) !important;
    }
  }
}

</style>


