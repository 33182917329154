<template>
    <div class="page-item page">
        <Topo :back="true" :notification="true" :plans="true"/>
        <v-main>
            <v-container class="fill-height" fluid>
                <v-row>
                    <v-col>
                        <v-card class="card-section" outlined>
                            <v-data-table :options="{ itemsPerPage: 10, }"
                                          no-data-text="Nenhum produto encontrado"
                                          :footer-props="{'items-per-page-options':[5,10, 20, 100, -1],'items-per-page-all-text':'Todos','items-per-page-text':'Itens por página',}"
                                          :headers="headers"
                                          :search="search"
                                          :items="items"
                                          sort-by="nome"
                                          class="elevation-1">

                                <template v-slot:item.situation="{ item }">
                                    <v-btn
                                            depressed
                                            :class="item.situation.id === 'S' ? 'btn-active' : 'btn-not-active'"
                                            :disabled="loading_situation  && item.id === situation_index"
                                            :loading="loading_situation  && item.id === situation_index"
                                            @click="situation_item(item, item.situation.id === 'S' ? 'N' : 'S'); situation_index = item.id">
                                        {{item.situation.id === 'S' ? 'Sim' : 'Não'}}
                                    </v-btn>
                                </template>

                                <template v-slot:top>
                                    <div class="topo-card">
                                        <v-row dense>
                                            <v-col md="4" sm="5" cols>
                                                <div class="d-flex">
                                                    <v-toolbar-title>Lista de depoimentos</v-toolbar-title>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon
                                                                   v-bind="attrs"
                                                                   v-on="on"
                                                                   class="ml-3"
                                                                   @click="dialog_info = true">
                                                                <v-icon>mdi-information-outline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Informação</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-col>
                                            <v-col md="8" sm="7" cols>
                                                <div class="d-flex">
                                                    <v-text-field
                                                            v-model="search"
                                                            class="search-card"
                                                            append-icon="mdi-magnify"
                                                            label="Buscar..."
                                                            solo
                                                            flat
                                                            hide-details
                                                    ></v-text-field>
                                                    <v-dialog v-model="dialog_add" max-width="500px" scrollable4>
                                                        <v-card>
                                                            <v-card-title>
                                                                <span class="card-title">Cadastrar</span>
                                                                <v-spacer></v-spacer>
                                                                <v-btn icon color="blue darken-1"
                                                                       v-on:click="resetForm()"
                                                                       @click="dialog_add = false">
                                                                    <v-icon>mdi-close</v-icon>
                                                                </v-btn>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <form>
                                                                    <v-text-field
                                                                            v-model="name"
                                                                            :error-messages="nameErrors"
                                                                            label="Nome"
                                                                            required
                                                                            outlined
                                                                            clearable
                                                                            dense
                                                                            @input="$v.name.$touch()"
                                                                            @blur="$v.name.$touch()"
                                                                    ></v-text-field>
                                                                    <v-text-field
                                                                            v-model="testimony"
                                                                            :error-messages="testimonyErrors"
                                                                            label="Depoimento"
                                                                            required
                                                                            outlined
                                                                            clearable
                                                                            dense
                                                                            @input="$v.testimony.$touch()"
                                                                            @blur="$v.testimony.$touch()"
                                                                    ></v-text-field>
                                                                    <v-select
                                                                            v-model="state"
                                                                            :items="states"
                                                                            item-text="name"
                                                                            item-value="id"
                                                                            @change="search_city(state.id)"
                                                                            :error-messages="stateErrors"
                                                                            label="Estados"
                                                                            outlined
                                                                            required
                                                                            return-object
                                                                            clearable
                                                                            dense
                                                                            @input="$v.state.$touch()"
                                                                            @blur="$v.state.$touch()"
                                                                    ></v-select>
                                                                    <v-select
                                                                            v-model="city"
                                                                            :items="cities"
                                                                            item-text="name"
                                                                            item-value="id"
                                                                            :error-messages="cityErrors"
                                                                            label="Cidades"
                                                                            outlined
                                                                            required
                                                                            return-object
                                                                            clearable
                                                                            dense
                                                                            @input="$v.city.$touch()"
                                                                            @blur="$v.city.$touch()"
                                                                    ></v-select>
                                                                    <v-select
                                                                            v-model="situation"
                                                                            :items="visivel"
                                                                            item-text="description"
                                                                            item-value="id"
                                                                            :error-messages="situationErrors"
                                                                            label="Visível"
                                                                            outlined
                                                                            required
                                                                            return-object
                                                                            clearable
                                                                            dense
                                                                            @input="$v.situation.$touch()"
                                                                            @blur="$v.situation.$touch()"
                                                                    ></v-select>
                                                                </form>
                                                            </v-card-text>
                                                            <v-divider></v-divider>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                        depressed
                                                                        :loading="loading"
                                                                        :disabled="loading"
                                                                        @click="add()">
                                                                    Salvar
                                                                </v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                    <v-dialog v-model="dialog_edit" max-width="500px" scrollable>
                                                        <v-card>
                                                            <v-card-title>
                                                                <span class="card-title">Editar</span>
                                                                <v-spacer></v-spacer>
                                                                <v-btn icon color="blue darken-1"
                                                                       @click="dialog_edit = false">
                                                                    <v-icon>mdi-close</v-icon>
                                                                </v-btn>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <form>
                                                                    <v-text-field
                                                                            v-model="name"
                                                                            :error-messages="nameErrors"
                                                                            label="Nome"
                                                                            required
                                                                            outlined
                                                                            clearable
                                                                            dense
                                                                            @input="$v.name.$touch()"
                                                                            @blur="$v.name.$touch()"
                                                                    ></v-text-field>
                                                                    <v-text-field
                                                                            v-model="testimony"
                                                                            :error-messages="testimonyErrors"
                                                                            label="Depoimento"
                                                                            required
                                                                            outlined
                                                                            clearable
                                                                            dense
                                                                            @input="$v.testimony.$touch()"
                                                                            @blur="$v.testimony.$touch()"
                                                                    ></v-text-field>
                                                                    <v-select
                                                                            v-model="state"
                                                                            :items="states"
                                                                            item-text="name"
                                                                            item-value="id"
                                                                            @change="search_city(state.id)"
                                                                            :error-messages="stateErrors"
                                                                            label="Estados"
                                                                            outlined
                                                                            required
                                                                            return-object
                                                                            clearable
                                                                            dense
                                                                            @input="$v.state.$touch()"
                                                                            @blur="$v.state.$touch()"
                                                                    ></v-select>
                                                                    <v-select
                                                                            v-model="city"
                                                                            :items="cities"
                                                                            item-text="name"
                                                                            item-value="id"
                                                                            :error-messages="cityErrors"
                                                                            label="Cidades"
                                                                            outlined
                                                                            required
                                                                            return-object
                                                                            clearable
                                                                            dense
                                                                            @input="$v.city.$touch()"
                                                                            @blur="$v.city.$touch()"
                                                                    ></v-select>
                                                                    <v-select
                                                                            v-model="situation"
                                                                            :items="visivel"
                                                                            item-text="description"
                                                                            item-value="id"
                                                                            :error-messages="situationErrors"
                                                                            label="Visível"
                                                                            outlined
                                                                            required
                                                                            return-object
                                                                            clearable
                                                                            dense
                                                                            @input="$v.situation.$touch()"
                                                                            @blur="$v.situation.$touch()"
                                                                    ></v-select>
                                                                </form>
                                                            </v-card-text>
                                                            <v-divider></v-divider>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                        depressed
                                                                        :loading="loading"
                                                                        :disabled="loading"
                                                                        @click="save_item()">
                                                                    Salvar
                                                                </v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                    <v-dialog v-model="dialog_delete" max-width="290">
                                                        <v-card class="card-delete">

                                                            <v-card-text class="text-center">
                                                                <v-icon>mdi-information-outline</v-icon>
                                                                <div>Tem certeza que deseja <strong>excluir?</strong>
                                                                </div>
                                                            </v-card-text>
                                                            <v-divider></v-divider>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>

                                                                <v-btn depressed
                                                                       @click="dialog_delete = false">
                                                                    Não
                                                                </v-btn>

                                                                <v-btn depressed :loading="loading" :disabled="loading"
                                                                       @click="excluir()">
                                                                    Sim
                                                                </v-btn>
                                                                <v-spacer></v-spacer>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                    <v-dialog v-model="dialog_upload" max-width="500px" scrollable>
                                                        <v-card class="card-upload">
                                                            <v-card-title>
                                                                <span class="card-title">Upload de arquivo</span>
                                                                <v-spacer></v-spacer>
                                                                <v-btn icon color="blue darken-1"
                                                                       @click="dialog_upload = false">
                                                                    <v-icon>mdi-close</v-icon>
                                                                </v-btn>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-file-input
                                                                        v-model="file"
                                                                        :error-messages="fileErrors"
                                                                        color="#263C4D"
                                                                        accept="image/png, image/jpeg, image/bmp, image/gif"
                                                                        dense
                                                                        counter
                                                                        show-size
                                                                        small-chips
                                                                        truncate-length="13"
                                                                        label="Imagem"
                                                                        placeholder="Selecione uma imagem"
                                                                        outlined
                                                                        @input="$v.file.$touch()"
                                                                        @blur="$v.file.$touch()">
                                                                </v-file-input>
                                                                <div class="img-card" v-if="item.file">
                                                                    <v-row>
                                                                        <v-col md="6">
                                                                            <div class="img">
                                                                                <v-img height="110"
                                                                                       :src="item.file.img"></v-img>
                                                                            </div>
                                                                        </v-col>
                                                                        <v-col md="6">
                                                                            <div class="information">
                                                                                <div class="date">Data:
                                                                                    {{item.date}}
                                                                                </div>
                                                                                <div class="size">Original:
                                                                                    {{formatBytes(item.file.size)}}
                                                                                </div>
                                                                                <div class="site-compressed">Comprimido:
                                                                                    {{formatBytes(item.file.compressed)}}
                                                                                </div>
                                                                                <v-btn depressed class="btn-delete-file"
                                                                                       :disabled="loading_file"
                                                                                       :loading="loading_file"
                                                                                       @click="delete_file(item)">
                                                                                    <v-icon>mdi-delete</v-icon>
                                                                                </v-btn>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </div>
                                                            </v-card-text>
                                                            <v-divider></v-divider>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                        depressed
                                                                        :loading="loading"
                                                                        :disabled="loading"
                                                                        @click="submit_file()">
                                                                    Salvar
                                                                </v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn dark depressed class="btn-add" v-bind="attrs"
                                                                   v-on="on"
                                                                   @click="resetForm(); dialog_add = true">
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Cadastrar</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                    v-bind="attrs" v-on="on"
                                                                    depressed
                                                                    color="primary"
                                                                    class="btn-refresh"
                                                                    :loading="loading_refresh"
                                                                    :disabled="loading_refresh"
                                                                    @click="initialize">
                                                                <v-icon>mdi-refresh</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Recarregar</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-menu class="menu-list" top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item link @click="edit(item)">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-image-edit-outline</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title>Editar</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item link @click="deleteItem(item)">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title>Excluir</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item link @click="upload(item)">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-upload</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title>Upload de arquivo</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                                <template v-slot:no-data>
                                    <v-btn :loading="loading_refresh"
                                           :disabled="loading_refresh"
                                           color="primary" depressed @click="initialize">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <v-dialog v-model="dialog_info" max-width="800px" scrollable>
            <v-card>
                <v-card-title>
                    <span class="card-title">Informação</span>
                    <v-spacer></v-spacer>
                    <v-btn icon color="blue darken-1"
                           v-on:click="resetForm()"
                           @click="dialog_info = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>

                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <Footer/>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate';
    import {required, minLength} from 'vuelidate/lib/validators';
    import Topo from "../../components/Topo";
    import Footer from "../../components/Footer";
    import {formatBytes, formatDate} from "@/utils.js";

    export default {
        name: "Depoimentos",
        components: {Topo, Footer},
        mixins: [validationMixin],
        data: () => ({
            search: '',
            headers: [
                {
                    text: 'Nome',
                    align: 'start',
                    value: 'name',
                },
                {text: 'Data de cadastro', value: 'date'},
                {text: 'Visível', value: 'situation', sortable: false},
                {text: 'Ação', value: 'actions', sortable: false},
            ],
            items: [],

            visivel: [{id: "S", description: "Sim"}, {id: "N", description: "Não"}],
            loading: false,
            loading_file: false,
            loading_situation: false,
            loading_refresh: false,
            dialog_add: false,
            dialog_edit: false,
            dialog_delete: false,
            dialog_upload: false,
            dialog_info: false,
            show: false,
            snackbar: {
                show: false,
                text: "",
                timeout: 7000,
            },
            item: {},
            index: -1,
            name: '',
            date: '',
            testimony: '',
            city: '',
            state: '',
            situation: '',
            file: [],
            states: [],
            cities: [],
        }),
        validations: {
            name: {
                required,
                minLength: minLength(5)
            },

            testimony: {
                required,
                minLength: minLength(20)
            },

            situation: {
                required
            },

            state: {
                required
            },

            city: {
                required
            },
            file: {
                required
            },
        },
        created() {
            this.initialize();
            this.search_state();
        },
        computed: {
            nameErrors() {
                const errors = []
                if (!this.$v.name.$dirty) return errors
                !this.$v.name.minLength && errors.push('O nome deve ter no mínino 5 caracteres.')
                !this.$v.name.required && errors.push('Nome é obrigatório.')
                return errors
            },

            testimonyErrors() {
                const errors = []
                if (!this.$v.testimony.$dirty) return errors
                !this.$v.testimony.minLength && errors.push('O depoimento deve ter no mínino 20 caracteres.')
                !this.$v.testimony.required && errors.push('Depoimento é obrigatório.')
                return errors
            },

            situationErrors() {
                const errors = []
                if (!this.$v.situation.$dirty) return errors
                !this.$v.situation.required && errors.push('Situação é obrigatório.')
                return errors
            },

            stateErrors() {
                const errors = [];
                if (!this.$v.state.$dirty) return errors;
                !this.$v.state.required && errors.push('Estado é obrigatório.');
                return errors
            },

            cityErrors() {
                const errors = [];
                if (!this.$v.city.$dirty) return errors;
                !this.$v.city.required && errors.push('Cidade é obrigatório.');
                return errors
            },

           fileErrors() {
                const errors = [];
                if (!this.$v.file.$dirty) return errors;
                !this.$v.file.required && errors.push('Imagem é obrigatório.');
                return errors
            },
        },
        methods: {
            formatBytes,formatDate,

            async initialize() {
                this.loading_refresh = true;
                await this.Api().post('/json/testimony_list', {
                    'token': this.$store.state.token,
                }).then(response => {
                    if (response.data.status === "invalid") {
                        this.$store.commit('logout');
                        this.$router.replace('/login');
                    } else {
                        this.items = response.data;
                        this.loading_refresh = false;
                    }
                }).catch(error => {
                    this.loading_refresh = false;
                    this.snackbar.show = true;
                    this.snackbar.text = error;
                    this.loading = false;
                });
            },

            async add() {
                this.$v.$touch();

                if (this.$v.name.$anyError || this.$v.testimony.$anyError || this.$v.state.$anyError || this.$v.city.$anyError || this.$v.situation.$anyError)
                {
                    return;
                }

                this.loading = true;

                setTimeout(() => {
                    const data = {
                        'token': this.$store.state.token,
                        'name': this.name,
                        'testimony': this.testimony,
                        'state': this.state.id,
                        'city': this.city.id,
                        'situation': this.situation,
                    };
                    this.Api().post('/json/add_testimony', data).then(response => {
                        switch (response.data.status) {
                            case "invalid":
                                this.$store.commit('logout');
                                this.$router.replace('/login');
                                break;

                            case "success":
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;

                                this.items.unshift({
                                    id: response.data.id,
                                    name: this.name,
                                    testimony: this.testimony,
                                    date: this.formatDate(new Date().toISOString().substr(0, 10)),
                                    situation: this.situation,
                                    state: this.state,
                                    city: this.city,
                                });

                                this.loading = false;
                                this.dialog_add = false;
                                this.resetForm();
                                break;

                            default:
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;
                                this.loading = false;
                                this.dialog_add = false;

                                break;
                        }
                    }).catch(error => {
                        this.snackbar.show = true;
                        this.snackbar.text = error;
                        this.loading = false;
                        this.dialog_add = false;
                    });
                }, 3000);
            },

            async edit(item) {
                this.index = this.items.indexOf(item);
                this.item = Object.assign({}, item);
                this.name = this.item.name;
                this.testimony = this.item.testimony;
                this.state = this.item.state;
                this.search_city(this.item.state.id);
                this.city = this.item.city;
                this.situation = this.item.situation;
                this.dialog_edit = true;
            },

            async save_item() {
                this.$v.$touch();

                if (this.$v.name.$anyError
                    || this.$v.testimony.$anyError
                    || this.$v.state.$anyError
                    || this.$v.city.$anyError
                    || this.$v.situation.$anyError)
                {
                    return;
                }


                this.loading = true;

                setTimeout(() => {
                    const data = {
                        'item': this.item.id,
                        'token': this.$store.state.token,
                        'name': this.name,
                        'testimony': this.testimony,
                        'state': this.state.id,
                        'city': this.city.id,
                        'situation': this.situation,
                    };

                    this.Api().post('/json/save_testimony', data).then(response => {
                        switch (response.data.status) {
                            case "invalid":
                                this.$store.commit('logout');
                                this.$router.replace('/login');
                                break;

                            case "success":
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;

                                Object.assign(this.items[this.index], {
                                    name: this.name,
                                    testimony: this.testimony,
                                    state: this.state,
                                    city: this.city,
                                    date: this.items[this.index].date,
                                    situation: this.situation
                                });
                                this.loading = false;
                                this.dialog_edit = false;
                                this.resetForm();
                                break;

                            default:
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;
                                this.loading = false;
                                this.dialog_edit = false;
                                break;
                        }
                    }).catch(error => {
                        this.snackbar.show = true;
                        this.snackbar.text = error;
                        this.loading = false;
                    });
                }, 3000);
            },

            async deleteItem(item) {
                this.index = this.items.indexOf(item);
                this.item = Object.assign({}, item);
                this.dialog_delete = true;
            },

            async excluir() {
                this.loading = true;

                setTimeout(() => {
                    const data = {
                        'token': this.$store.state.token,
                        'item': this.item.id,
                    };
                    this.Api().post('/json/delete_testimony', data).then(response => {
                        switch (response.data.status) {
                            case "invalid":
                                this.$store.commit('logout');
                                this.$router.replace('/login');
                                break;

                            case "success":
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;
                                this.items.splice(this.index, 1);
                                this.loading = false;
                                this.dialog_delete = false;
                                break;

                            default:
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;
                                this.loading = false;
                                this.dialog_delete = false;
                                break;
                        }

                    }).catch(error => {
                        this.snackbar.show = true;
                        this.snackbar.text = error;
                        this.loading = false;
                        this.dialog_add = false;
                    });
                }, 3000);

            },

            async upload(item) {
                this.index = this.items.indexOf(item);
                this.item = Object.assign({}, item);
                this.dialog_upload = true;
            },

            async submit_file() {

                this.$v.$touch();

                if (this.$v.file.$anyError) {
                    return;
                }

                this.loading = true;
                var result = true;
                var size = this.file.size;
                if ((size / 1024) > process.env.VUE_APP_SIZE_LIMIT) {
                    this.snackbar.show = true;
                    this.snackbar.text = 'Não é possível enviar um arquivo com tamanho superior a ' + formatBytes(process.env.VUE_APP_SIZE_LIMIT * 1024);
                    this.loading = false;
                    result = false;
                }

                if (result) {
                    let formData = new FormData();
                    formData.append('file', this.file);
                    formData.append('token', this.$store.state.token);
                    formData.append('item', this.item.id);

                    setTimeout(() => {
                        this.Api().post('/json/send_image_testimony', formData).then(response => {

                            switch (response.data.status) {
                                case "invalid":
                                    this.$store.commit('logout');
                                    this.$router.replace('/login');
                                    break;

                                case "success":
                                    this.snackbar.show = true;
                                    this.snackbar.text = response.data.text;
                                    this.$set(this.items[this.index], 'file', response.data.file);
                                    this.$set(this.item, 'file', response.data.file);
                                    this.loading = false;
                                    this.resetForm();
                                    break;

                                default:
                                    this.snackbar.show = true;
                                    this.snackbar.text = response.data.text;
                                    this.loading = false;
                                    this.resetForm();
                                    break;
                            }

                            if (response.data.status === "success") {
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;
                                this.loading = false;
                                this.resetForm();
                            } else {
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;
                                this.loading = false;
                                this.resetForm();
                            }
                        }).catch(error => {
                            this.snackbar.show = true;
                            this.snackbar.text = error;
                            this.loading = false;
                            this.resetForm();
                        });
                    }, 3000);
                }
            },

            async delete_file(date) {
                this.loading_file = true;

                setTimeout(() => {
                    const data = {
                        'token': this.$store.state.token,
                        'item': date.id
                    };
                    this.Api().post('/json/delete_image_testimony', data).then(response => {
                        switch (response.data.status) {
                            case "invalid":
                                this.$store.commit('logout');
                                this.$router.replace('/login');
                                break;

                            case "success":
                                this.index = this.items.findIndex(r => date.id === r.id);
                                this.$set(this.items[this.index], 'file', false);
                                this.$set(this.item, 'file', false);

                                this.loading_file = false;
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;
                                break;

                            default:
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;
                                this.loading_file = false;
                                this.loading_file = false;
                                break;
                        }
                    }).catch(error => {
                        this.snackbar.show = true;
                        this.snackbar.text = error;
                        this.loading_file = false;
                    });
                }, 3000);

            },

            async situation_item(item, r) {
                this.loading_situation = true;

                setTimeout(() => {
                    const data = {
                        'token': this.$store.state.token,
                        'item': item.id,
                        'situation': r
                    };
                    this.Api().post('/json/situation_testimony', data).then(response => {
                        switch (response.data.status) {
                            case "invalid":
                                localStorage.removeItem('token');
                                localStorage.removeItem('user');
                                this.$router.push({path: "/login",}).catch(err => {
                                    console.log(err)
                                });
                                break;

                            case "success":
                                this.index = this.items.findIndex(r => item.id === r.id);
                                this.$set(this.items[this.index].situation, 'id', r === 'S' ? 'S' : 'N');

                                this.loading_situation = false;
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;
                                break;

                            default:
                                this.snackbar.show = true;
                                this.snackbar.text = response.data.text;
                                this.loading_situation = false;
                                break;
                        }
                    }).catch(error => {
                        this.snackbar.show = true;
                        this.snackbar.text = error;
                        this.loading_situation = false;
                    });
                }, 1000);
            },

            async search_state() {
                await this.Api().post('/json/search_state', {
                    'token': this.$store.state.token,
                }).then(response => {
                    this.states = response.data;
                }).catch(error => {
                    this.snackbar.show = true;
                    this.snackbar.text = error;
                    this.loading = false;
                });
            },

            async search_city(data) {
                await this.Api().post('/json/search_city', {
                    'token': this.$store.state.token,
                    'state': data,
                }).then(response => {
                    this.cities = response.data;
                }).catch(error => {
                    this.snackbar.show = true;
                    this.snackbar.text = error;
                    this.loading = false;
                });
            },

            resetForm() {
                this.name = '';
                this.testimony = '';
                this.state = '';
                this.city = '';
                this.situation = '';
                this.file = [];
                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
        }
    }
</script>
