<template>
  <div class="page-event page">
    <Topo :back="true" :notification="true" :plans="true"/>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row>
          <v-col>
            <v-card class="card-section" outlined>
              <v-data-table :options="{ itemsPerPage: 10, }"
                            no-data-text="Nenhum produto encontrado"
                            :footer-props="{'items-per-page-options':[5,10, 20, 100, -1],'items-per-page-all-text':'Todos','items-per-page-text':'Itens por página',}"
                            :headers="headers"
                            :search="search"
                            :items="items"
                            sort-by="nome"
                            class="elevation-1">
                <template v-slot:item.situation="{ item }">
                  <v-btn
                      depressed
                      :class="item.situation.id === 'S' ? 'btn-active' : 'btn-not-active'"
                      :disabled="loading_situation  && item.id === situation_index"
                      :loading="loading_situation  && item.id === situation_index"
                      @click="situation_item(item, item.situation.id === 'S' ? 'N' : 'S'); situation_index = item.id">
                    {{ item.situation.id === 'S' ? 'Sim' : 'Não' }}
                  </v-btn>
                </template>

                <template v-slot:item.featured="{ item }">
                  <v-btn
                      depressed
                      :class="item.featured ? 'btn-active' : 'btn-not-active'"
                      :disabled="loading_featured && item.id === featured_index"
                      :loading="loading_featured && item.id === featured_index"
                      @click="featured_item(item, item.featured ? 'N' : 'S'); featured_index = item.id">
                    {{ item.featured ? 'Sim' : 'Não' }}
                  </v-btn>
                </template>

                <template v-slot:top>
                  <div class="topo-card">
                    <v-row dense>
                      <v-col md="4" cols>
                        <div class="d-flex">
                          <v-toolbar-title>Lista de agendas</v-toolbar-title>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon
                                     v-bind="attrs"
                                     v-on="on"
                                     class="ml-3"
                                     @click="dialog_info = true">
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Informação</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                      <v-col md="8" cols>
                        <div class="d-flex">
                          <v-text-field
                              v-model="search"
                              class="search-card"
                              append-icon="mdi-magnify"
                              label="Buscar..."
                              solo
                              flat
                              hide-details
                          ></v-text-field>
                          <v-dialog v-model="dialog_add" max-width="500px" scrollable>
                            <v-card>
                              <v-card-title>
                                <span class="card-title">Cadastrar</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       v-on:click="resetForm()"
                                       @click="dialog_add = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-text-field
                                      v-model="title"
                                      :error-messages="titleErrors"
                                      label="Título"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.title.$touch()"
                                      @blur="$v.title.$touch()"
                                  ></v-text-field>

                                  <v-row dense>
                                    <v-col sm="6">
                                      <v-menu v-model="menustartadd"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="290px">
                                        <template
                                            v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                              v-model="startdateFormatted"
                                              :error-messages="startdateErrors"
                                              label="Data de início"
                                              prepend-inner-icon="mdi-calendar"
                                              required
                                              outlined
                                              clearable
                                              dense
                                              v-bind="attrs"
                                              v-on="on"
                                              @input="$v.startdate.$touch()"
                                              @blur="$v.startdate.$touch(); startdate = parseDate(startdateFormatted)"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="startdate"
                                            no-title
                                            @input="menustartadd = false"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                    <v-col sm="6">
                                      <v-menu
                                          ref="menu"
                                          v-model="menustarttimeadd"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          :return-value.sync="starttime"
                                          transition="scale-transition"
                                          offset-y
                                          max-width="290px"
                                          min-width="290px"
                                      >
                                        <template
                                            v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                              v-model="starttime"
                                              :error-messages="starttimeErrors"
                                              label="Horário de início"
                                              prepend-inner-icon="mdi-clock-time-four-outline"
                                              required
                                              outlined
                                              clearable
                                              dense
                                              v-bind="attrs"
                                              v-on="on"
                                              @input="$v.starttime.$touch()"
                                              @blur="$v.starttime.$touch()"
                                          ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-if="menustarttimeadd"
                                            v-model="starttime"
                                            full-width
                                            format="24hr"
                                            @click:minute="$refs.menu.save(starttime)"
                                        ></v-time-picker>
                                      </v-menu>
                                    </v-col>
                                  </v-row>

                                  <v-row dense>
                                    <v-col sm="6">
                                      <v-menu v-model="menuendadd"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="290px">
                                        <template
                                            v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                              v-model="enddateFormatted"
                                              :error-messages="enddateErrors"
                                              label="Data de término"
                                              prepend-inner-icon="mdi-calendar"
                                              required
                                              outlined
                                              clearable
                                              dense
                                              v-bind="attrs"
                                              v-on="on"
                                              @input="$v.enddate.$touch()"
                                              @blur="$v.enddate.$touch(); enddate = parseDate(enddateFormatted)"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="enddate"
                                            no-title
                                            @input="menuendadd = false"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                    <v-col sm="6">
                                      <v-menu
                                          ref="menu2"
                                          v-model="menuendtimeadd"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          :return-value.sync="endtime"
                                          transition="scale-transition"
                                          offset-y
                                          max-width="290px"
                                          min-width="290px"
                                      >
                                        <template
                                            v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                              v-model="endtime"
                                              :error-messages="endtimeErrors"
                                              label="Horário de término"
                                              prepend-inner-icon="mdi-clock-time-four-outline"
                                              required
                                              outlined
                                              clearable
                                              dense
                                              v-bind="attrs"
                                              v-on="on"
                                              @input="$v.endtime.$touch()"
                                              @blur="$v.endtime.$touch()"
                                          ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-if="menuendtimeadd"
                                            v-model="endtime"
                                            full-width
                                            format="24hr"
                                            @click:minute="$refs.menu2.save(endtime)"
                                        ></v-time-picker>
                                      </v-menu>
                                    </v-col>
                                  </v-row>

                                  <div class="editor mb-4">
                                    <vue-editor v-model="description"></vue-editor>
                                  </div>

                                  <v-text-field
                                      v-model="address"
                                      :error-messages="addressErrors"
                                      label="Endereço"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.address.$touch()"
                                      @blur="$v.address.$touch()"
                                  ></v-text-field>

                                  <v-text-field
                                      v-model="state"
                                      :error-messages="stateErrors"
                                      label="Estado"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.state.$touch()"
                                      @blur="$v.state.$touch()"
                                  ></v-text-field>

                                  <v-text-field
                                      v-model="city"
                                      :error-messages="cityErrors"
                                      label="Cidade"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.city.$touch()"
                                      @blur="$v.city.$touch()"
                                  ></v-text-field>

                                  <v-select
                                      v-model="category"
                                      :items="categories"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="categoryErrors"
                                      label="Categorias"
                                      outlined
                                      required
                                      return-object
                                      clearable
                                      dense
                                      @input="$v.category.$touch()"
                                      @blur="$v.category.$touch()"
                                  ></v-select>

                                  <v-select
                                      v-model="situation"
                                      :items="visivel"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="situationErrors"
                                      label="Visível"
                                      outlined
                                      required
                                      return-object
                                      dense
                                      clearable
                                      @input="$v.situation.$touch()"
                                      @blur="$v.situation.$touch()"
                                  ></v-select>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="add()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_edit" max-width="500px" scrollable>
                            <v-card tile>
                              <v-card-title>
                                <span class="card-title">Editar</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       @click="dialog_edit = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-text-field
                                      v-model="title"
                                      :error-messages="titleErrors"
                                      label="Título"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.title.$touch()"
                                      @blur="$v.title.$touch()"
                                  ></v-text-field>

                                  <v-row dense>
                                    <v-col sm="6">
                                      <v-menu v-model="menustart"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="290px">
                                        <template
                                            v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                              v-model="startdateFormatted"
                                              :error-messages="startdateErrors"
                                              label="Data de início"
                                              prepend-inner-icon="mdi-calendar"
                                              required
                                              outlined
                                              clearable
                                              dense
                                              v-bind="attrs"
                                              v-on="on"
                                              @input="$v.startdate.$touch()"
                                              @blur="$v.startdate.$touch(); startdate = parseDate(startdateFormatted)"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="startdate"
                                            no-title
                                            @input="menustart = false"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                    <v-col sm="6">
                                      <v-menu
                                          ref="menu"
                                          v-model="menustarttime"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          :return-value.sync="starttime"
                                          transition="scale-transition"
                                          offset-y
                                          max-width="290px"
                                          min-width="290px"
                                      >
                                        <template
                                            v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                              v-model="starttime"
                                              :error-messages="starttimeErrors"
                                              label="Horário de início"
                                              prepend-inner-icon="mdi-clock-time-four-outline"
                                              required
                                              outlined
                                              clearable
                                              dense
                                              v-bind="attrs"
                                              v-on="on"
                                              @input="$v.starttime.$touch()"
                                              @blur="$v.starttime.$touch()"
                                          ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-if="menustarttime"
                                            v-model="starttime"
                                            full-width
                                            format="24hr"
                                            @click:minute="$refs.menu.save(starttime)"
                                        ></v-time-picker>
                                      </v-menu>
                                    </v-col>
                                  </v-row>

                                  <v-row dense>
                                    <v-col sm="6">
                                      <v-menu v-model="menuend"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="290px">
                                        <template
                                            v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                              v-model="enddateFormatted"
                                              :error-messages="enddateErrors"
                                              label="Data de término"
                                              prepend-inner-icon="mdi-calendar"
                                              required
                                              outlined
                                              clearable
                                              dense
                                              v-bind="attrs"
                                              v-on="on"
                                              @input="$v.enddate.$touch()"
                                              @blur="$v.enddate.$touch(); enddate = parseDate(enddateFormatted)"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="enddate"
                                            no-title
                                            @input="menuend = false"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                    <v-col sm="6">
                                      <v-menu
                                          ref="menu2"
                                          v-model="menuendtime"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          :return-value.sync="endtime"
                                          transition="scale-transition"
                                          offset-y
                                          max-width="290px"
                                          min-width="290px"
                                      >
                                        <template
                                            v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                              v-model="endtime"
                                              :error-messages="endtimeErrors"
                                              label="Horário de término"
                                              prepend-inner-icon="mdi-clock-time-four-outline"
                                              required
                                              outlined
                                              clearable
                                              dense
                                              v-bind="attrs"
                                              v-on="on"
                                              @input="$v.endtime.$touch()"
                                              @blur="$v.endtime.$touch()"
                                          ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-if="menuendtime"
                                            v-model="endtime"
                                            full-width
                                            format="24hr"
                                            @click:minute="$refs.menu2.save(endtime)"
                                        ></v-time-picker>
                                      </v-menu>
                                    </v-col>
                                  </v-row>

                                  <div class="editor mb-4">
                                    <vue-editor v-model="description"></vue-editor>
                                  </div>

                                  <v-text-field
                                      v-model="address"
                                      :error-messages="addressErrors"
                                      label="Endereço"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.address.$touch()"
                                      @blur="$v.address.$touch()"
                                  ></v-text-field>

                                  <v-text-field
                                      v-model="state"
                                      :error-messages="stateErrors"
                                      label="Estado"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.state.$touch()"
                                      @blur="$v.state.$touch()"
                                  ></v-text-field>

                                  <v-text-field
                                      v-model="city"
                                      :error-messages="cityErrors"
                                      label="Cidade"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.city.$touch()"
                                      @blur="$v.city.$touch()"
                                  ></v-text-field>

                                  <v-select
                                      v-model="category"
                                      :items="categories"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="categoryErrors"
                                      label="Categorias"
                                      outlined
                                      required
                                      return-object
                                      clearable
                                      dense
                                      @input="$v.category.$touch()"
                                      @blur="$v.category.$touch()"
                                  ></v-select>

                                  <v-select
                                      v-model="situation"
                                      :items="visivel"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="situationErrors"
                                      label="Visível"
                                      outlined
                                      required
                                      return-object
                                      dense
                                      clearable
                                      @input="$v.situation.$touch()"
                                      @blur="$v.situation.$touch()"
                                  ></v-select>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="save_item()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_delete" max-width="290">
                            <v-card class="card-delete">
                              <v-card-text class="text-center">
                                <v-icon>mdi-information-outline</v-icon>
                                <div>Tem certeza que deseja <strong>excluir?</strong>
                                </div>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn depressed
                                       @click="dialog_delete = false">
                                  Não
                                </v-btn>

                                <v-btn depressed :loading="loading" :disabled="loading"
                                       @click="excluir()">
                                  Sim
                                </v-btn>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_upload" max-width="1185px" scrollable>
                            <v-card class="card-upload">
                              <v-card-title>
                                <span class="card-title">Upload de arquivo</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       @click="dialog_upload = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <v-tabs v-model="tab" flat centered icons-and-text
                                        show-arrows class="mb-5">
                                  <v-tabs-slider></v-tabs-slider>

                                  <v-tab @click="submit = 1">
                                    Imagens
                                    <v-icon>mdi-image</v-icon>
                                  </v-tab>

                                  <v-tab @click="submit = 2">
                                    Vídeos
                                    <v-icon>mdi-video</v-icon>
                                  </v-tab>

                                  <v-tab @click="submit = 3">
                                    Audios
                                    <v-icon>mdi-music</v-icon>
                                  </v-tab>

                                  <v-tab @click="submit = 4">
                                    Arquivos
                                    <v-icon>mdi-file-document-outline</v-icon>
                                  </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tab">
                                  <v-tab-item>
                                    <v-file-input
                                        v-model="image"
                                        :error-messages="imageErrors"
                                        color="#263C4D"
                                        accept="image/png, image/jpeg, image/bmp, image/gif"
                                        dense
                                        counter
                                        multiple
                                        show-size
                                        small-chips
                                        truncate-length="13"
                                        label="Imagens"
                                        placeholder="Selecione suas imagens"
                                        outlined
                                        @input="$v.image.$touch()"
                                        @blur="$v.image.$touch()">
                                    </v-file-input>
                                    <v-row v-if="item.image">
                                      <v-col md="3" sm="4" cols
                                             v-for="(files, i) in item.image"
                                             :key="i">
                                        <v-card outlined>
                                          <v-img height="180"
                                                 :src="files.img"></v-img>
                                          <v-card-text>
                                            <div class="information">
                                              <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{ on, attrs }">
                                                  <div v-bind="attrs"
                                                       v-on="on"
                                                       class="original">
                                                    Arquivo:
                                                    {{ files.original }}
                                                  </div>
                                                </template>
                                                <span>{{ files.original }}</span>
                                              </v-tooltip>
                                              <div class="date">Data:
                                                {{ files.date }}
                                              </div>
                                              <div class="size">Original:
                                                {{ formatBytes(files.size) }}
                                              </div>
                                              <div class="site-compressed">
                                                Otimizado:
                                                {{ formatBytes(files.compressed) }}
                                              </div>
                                              <v-text-field
                                                  class="mt-3"
                                                  v-model="description_image[i]"
                                                  label="Descrição"
                                                  outlined
                                                  clearable
                                                  dense
                                                  hide-details
                                                  @change="description_file_image(files, item)"
                                              ></v-text-field>
                                            </div>
                                          </v-card-text>
                                          <v-card-actions
                                              class="card-actions-upload">
                                            <v-tooltip bottom>
                                              <template
                                                  v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs"
                                                       v-on="on"
                                                       depressed
                                                       class="btn-delete-file mr-2"
                                                       :disabled="loading_delete && i === loading_index"
                                                       :loading="loading_delete && i === loading_index"
                                                       @click="delete_file(files, item); loading_index = i">
                                                  <v-icon>mdi-delete
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Excluir arquivo</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                              <template
                                                  v-slot:activator="{ on, attrs }">
                                                <v-btn depressed
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       :class="files.cover === 'S' ? 'btn-capa-active mr-2' : 'btn-capa-img mr-2'"
                                                       :disabled="loading_cover && i === loading_index"
                                                       :loading="loading_cover && i === loading_index"
                                                       @click="cover_image(files, item); loading_index = i">
                                                  <v-icon v-if="files.cover === 'S'">
                                                    mdi-image-outline
                                                  </v-icon>
                                                  <v-icon v-else>
                                                    mdi-image-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span v-if="files.cover === 'S'">Capa cadastrada</span>
                                              <span v-else>Cadastrar capa</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                              <template
                                                  v-slot:activator="{ on, attrs }">
                                                <v-btn class="btn-download"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       depressed
                                                       @click="download(files.download)">
                                                  <v-icon>
                                                    mdi-download
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Dowload</span>
                                            </v-tooltip>
                                          </v-card-actions>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-tab-item>
                                  <v-tab-item>
                                    <form>
                                      <v-text-field
                                          v-model="url"
                                          :error-messages="urlErrors"
                                          label="URL do Youtube"
                                          required
                                          outlined
                                          clearable
                                          dense
                                          @input="$v.url.$touch()"
                                          @blur="$v.url.$touch()"
                                      ></v-text-field>
                                      <video-embed :src="url"></video-embed>
                                    </form>
                                    <v-row v-if="item.video">
                                      <v-col md="3" sm="4" cols
                                             v-for="(files, i) in item.video"
                                             :key="i">
                                        <v-card outlined>
                                          <video-embed
                                              :src="files.url"></video-embed>
                                          <v-card-text>
                                            <div class="information">
                                              <div class="date">Data:
                                                {{ files.date }}
                                              </div>
                                              <v-text-field
                                                  class="mt-3"
                                                  v-model="description_video[i]"
                                                  label="Descrição"
                                                  outlined
                                                  clearable
                                                  dense
                                                  hide-details
                                                  @change="description_file_video(files, item)"
                                              ></v-text-field>
                                            </div>
                                          </v-card-text>
                                          <v-card-actions
                                              class="card-actions-upload">
                                            <v-tooltip bottom>
                                              <template
                                                  v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs"
                                                       v-on="on"
                                                       depressed
                                                       class="btn-delete-file mr-2"
                                                       :disabled="loading_delete && i === loading_index"
                                                       :loading="loading_delete && i === loading_index"
                                                       @click="delete_file(files, item); loading_index = i">
                                                  <v-icon>mdi-delete
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Excluir arquivo</span>
                                            </v-tooltip>
                                          </v-card-actions>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-tab-item>
                                  <v-tab-item>
                                    <v-file-input
                                        v-model="audio"
                                        :error-messages="audioErrors"
                                        color="#263C4D"
                                        accept="audio/mp3, audio/ogg, audio/wma, audio/wav, audio/aav"
                                        dense
                                        counter
                                        multiple
                                        show-size
                                        small-chips
                                        truncate-length="13"
                                        label="Audio"
                                        placeholder="Selecione seus audios"
                                        outlined
                                        @input="$v.audio.$touch()"
                                        @blur="$v.audio.$touch()">
                                    </v-file-input>
                                    <v-row v-if="item.audio">
                                      <v-col md="3" sm="4" cols
                                             v-for="(files, i) in item.audio"
                                             :key="i">
                                        <v-card outlined>
                                          <vuetify-audio :file="files.audio"
                                                         flat></vuetify-audio>
                                          <v-card-text>
                                            <div class="information">
                                              <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{ on, attrs }">
                                                  <div v-bind="attrs"
                                                       v-on="on"
                                                       class="original">
                                                    Arquivo:
                                                    {{ files.original }}
                                                  </div>
                                                </template>
                                                <span>{{ files.original }}</span>
                                              </v-tooltip>

                                              <div class="date">Data:
                                                {{ files.date }}
                                              </div>
                                              <div class="size">Original:
                                                {{ formatBytes(files.size) }}
                                              </div>
                                              <v-text-field
                                                  class="mt-3"
                                                  v-model="description_audio[i]"
                                                  label="Descrição"
                                                  outlined
                                                  clearable
                                                  dense
                                                  hide-details
                                                  @change="description_file_audio(files, item)"
                                              ></v-text-field>
                                            </div>
                                          </v-card-text>
                                          <v-card-actions class="card-actions-upload">
                                            <v-tooltip bottom>
                                              <template
                                                  v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs"
                                                       v-on="on"
                                                       depressed
                                                       class="btn-delete-file mr-2"
                                                       :disabled="loading_delete && i === loading_index"
                                                       :loading="loading_delete && i === loading_index"
                                                       @click="delete_file(files, item); loading_index = i">
                                                  <v-icon>mdi-delete
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Excluir arquivo</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                              <template
                                                  v-slot:activator="{ on, attrs }">
                                                <v-btn class="btn-download"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       depressed
                                                       @click="download(files.download)">
                                                  <v-icon>
                                                    mdi-download
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Dowload</span>
                                            </v-tooltip>
                                          </v-card-actions>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-tab-item>
                                  <v-tab-item>
                                    <v-file-input
                                        v-model="file"
                                        :error-messages="fileErrors"
                                        color="#263C4D"
                                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"
                                        dense
                                        counter
                                        multiple
                                        show-size
                                        small-chips
                                        truncate-length="13"
                                        label="Arquivos"
                                        placeholder="Selecione seus arquivos"
                                        outlined
                                        @input="$v.audio.$touch()"
                                        @blur="$v.audio.$touch()">
                                    </v-file-input>
                                    <v-row v-if="item.file">
                                      <v-col md="3" sm="4" cols
                                             v-for="(files, i) in item.file"
                                             :key="i">
                                        <v-card outlined>
                                          <v-card-text>
                                            <v-icon>mdi-file-pdf-outline
                                            </v-icon>
                                            <div class="information">
                                              <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{ on, attrs }">
                                                  <div v-bind="attrs"
                                                       v-on="on"
                                                       class="original">
                                                    Arquivo:
                                                    {{ files.original }}
                                                  </div>
                                                </template>
                                                <span>{{ files.original }}</span>
                                              </v-tooltip>
                                              <div class="date">Data:
                                                {{ files.date }}
                                              </div>
                                              <div class="size">Original:
                                                {{ formatBytes(files.size) }}
                                              </div>
                                              <v-text-field
                                                  class="mt-3"
                                                  v-model="description_file[i]"
                                                  label="Descrição"
                                                  outlined
                                                  clearable
                                                  dense
                                                  hide-details
                                                  @change="description_file_file(files, item)"
                                              ></v-text-field>
                                            </div>
                                          </v-card-text>
                                          <v-card-actions class="card-actions-upload">
                                            <v-tooltip bottom>
                                              <template
                                                  v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs"
                                                       v-on="on"
                                                       depressed
                                                       class="btn-delete-file mr-2"
                                                       :disabled="loading_delete && i === loading_index"
                                                       :loading="loading_delete && i === loading_index"
                                                       @click="delete_file(files, item); loading_index = i">
                                                  <v-icon>mdi-delete
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Excluir arquivo</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="btn-download"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       depressed
                                                       @click="download(files.download)">
                                                  <v-icon>
                                                    mdi-download
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Dowload</span>
                                            </v-tooltip>
                                          </v-card-actions>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-tab-item>
                                </v-tabs-items>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="submit_file()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn dark
                                     depressed
                                     class="btn-add-category"
                                     v-bind="attrs"
                                     v-on="on"
                                     to="/categoriasagenda">
                                <v-icon>mdi-filter-plus-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Cadastrar categorias</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn dark depressed class="btn-add" v-bind="attrs"
                                     v-on="on"
                                     @click="resetForm(); dialog_add = true">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Cadastrar</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs" v-on="on"
                                  depressed
                                  color="primary"
                                  class="btn-refresh"
                                  :loading="loading_refresh"
                                  :disabled="loading_refresh"
                                  @click="initialize">
                                <v-icon>mdi-refresh</v-icon>
                              </v-btn>
                            </template>
                            <span>Recarregar</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-menu class="menu-list" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link @click="edit(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-calendar-edit</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item link @click="deleteItem(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Excluir</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item link @click="upload(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-upload</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Upload de arquivo</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>

                <template v-slot:no-data>
                  <v-btn :loading="loading_refresh"
                         :disabled="loading_refresh"
                         color="primary" depressed @click="initialize">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-dialog v-model="dialog_info" max-width="800px" scrollable>
      <v-card>
        <v-card-title>
          <span class="card-title">Informação</span>
          <v-spacer></v-spacer>
          <v-btn icon color="blue darken-1"
                 v-on:click="resetForm()"
                 @click="dialog_info = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <Footer/>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, minLength} from 'vuelidate/lib/validators';
import Topo from "../../components/Topo";
import Footer from "../../components/Footer";
import {formatBytes, parseDate, formatDate} from "@/utils.js";
import {VueEditor} from "vue2-editor";
import VuetifyAudio from "vuetify-audio";

export default {
  name: "Agendas",
  components: {Topo, Footer, VueEditor, VuetifyAudio},
  mixins: [validationMixin],

  data: (vm) => ({
    tab: null,
    search: '',
    headers: [
      {
        text: 'Título',
        align: 'start',
        value: 'title',
      },
      {text: 'Data de início', value: 'startdate'},
      {text: 'Data de término', value: 'enddate'},
      {text: 'Categoria', value: 'category.description'},
      {text: 'Visível', value: 'situation', sortable: false},
      {text: 'Destaque', value: 'featured', sortable: false},
      {text: 'Ação', value: 'actions', sortable: false},
    ],
    items: [],
    categories: [],
    visivel: [{id: "S", description: "Sim"}, {id: "N", description: "Não"}],
    loading: false,
    loading_file: false,
    loading_cover: false,
    loading_delete: false,
    loading_featured: false,
    loading_situation: false,
    loading_refresh: false,
    loading_index: -1,
    situation_index: -1,
    featured_index: -1,
    dialog_add: false,
    dialog_edit: false,
    dialog_delete: false,
    dialog_upload: false,
    dialog_info: false,
    show: false,
    snackbar: {
      show: false,
      text: "",
      timeout: 7000,
    },
    item: {},
    index: -1,
    title: '',
    startdate: new Date().toISOString().substr(0, 10),
    startdateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    enddate: new Date().toISOString().substr(0, 10),
    enddateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    starttime: '',
    endtime: '',
    category: '',
    description: '',
    address: '',
    state: '',
    city: '',
    situation: '',
    featured: '',
    description_image: [],
    description_video: [],
    description_audio: [],
    description_file: [],
    submit: 1,
    file: [],
    audio: [],
    image: [],

    menustartadd: false,
    menustart: false,
    menuendadd: false,
    menuend: false,
    menuendtime: false,
    menuendtimeadd: false,
    menustarttimeadd: false,
    menustarttime: false,
    url: '',
  }),

  validations: {
    title: {
      required,
      minLength: minLength(5)
    },
    startdate: {
      required,
    },
    enddate: {
      required,
    },
    starttime: {
      required,
    },
    endtime: {
      required,
    },
    category: {
      required,
    },
    situation: {
      required
    },
    address: {
      required,
      minLength: minLength(5)
    },
    state: {
      required
    },
    city: {
      required
    },
    image: {
      required
    },
    url: {
      required,
      minLength: minLength(20)
    },
    audio: {
      required
    },
    file: {
      required
    },
  },

  async created() {
    await this.initialize();
    await this.search_category();
  },

  computed: {
    titleErrors() {
      const errors = []
      if (!this.$v.title.$dirty) return errors
      !this.$v.title.minLength && errors.push('O título deve ter no mínino 5 caracteres.')
      !this.$v.title.required && errors.push('Título é obrigatório.')
      return errors
    },
    categoryErrors() {
      const errors = []
      if (!this.$v.category.$dirty) return errors
      !this.$v.category.required && errors.push('Categoria é obrigatório.')
      return errors
    },
    situationErrors() {
      const errors = []
      if (!this.$v.situation.$dirty) return errors
      !this.$v.situation.required && errors.push('Situação é obrigatório.')
      return errors
    },
    startdateErrors() {
      const errors = []
      if (!this.$v.startdate.$dirty) return errors
      !this.$v.startdate.required && errors.push('Data de início é obrigatório.')
      return errors
    },
    enddateErrors() {
      const errors = []
      if (!this.$v.enddate.$dirty) return errors
      !this.$v.enddate.required && errors.push('Data de término é obrigatório.')
      return errors
    },
    starttimeErrors() {
      const errors = []
      if (!this.$v.starttime.$dirty) return errors
      !this.$v.starttime.required && errors.push('Horário é obrigatório.')
      return errors
    },
    endtimeErrors() {
      const errors = []
      if (!this.$v.endtime.$dirty) return errors
      !this.$v.endtime.required && errors.push('Horário é obrigatório.')
      return errors
    },
    addressErrors() {
      const errors = []
      if (!this.$v.address.$dirty) return errors
      !this.$v.address.minLength && errors.push('O enderço deve ter no mínino 5 caracteres.')
      !this.$v.address.required && errors.push('O enderço é obrigatório.')
      return errors
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push('Estado é obrigatório.');
      return errors
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push('Cidade é obrigatório.');
      return errors
    },
    imageErrors() {
      const errors = []
      if (!this.$v.image.$dirty) return errors
      !this.$v.image.required && errors.push('Imagem é obrigatório.')
      return errors
    },
    urlErrors() {
      const errors = []
      if (!this.$v.url.$dirty) return errors
      !this.$v.url.minLength && errors.push('A URL deve ter no mínino 20 caracteres.')
      !this.$v.url.required && errors.push('Título é obrigatório.')
      return errors
    },
    audioErrors() {
      const errors = []
      if (!this.$v.audio.$dirty) return errors
      !this.$v.audio.required && errors.push('Audio é obrigatório.')
      return errors
    },
    fileErrors() {
      const errors = []
      if (!this.$v.file.$dirty) return errors
      !this.$v.file.required && errors.push('Arquivo é obrigatório.')
      return errors
    },
  },

  watch: {
    startdate() {
      this.startdateFormatted = this.formatDate(this.startdate);
    },
    enddate() {
      this.enddateFormatted = this.formatDate(this.enddate);
    },
  },

  methods: {
    formatBytes, parseDate, formatDate,

    async initialize() {
      this.loading_refresh = true;
      await this.Api().post('/json/event_list', {
        'token': this.$store.state.token,
      }).then(response => {
        if (response.data.status === "invalid") {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          this.$router.push({path: "/login",}).catch(err => {
            console.log(err)
          });
        } else {
          this.items = response.data;
          this.loading_refresh = false;
        }
      }).catch(error => {
        this.loading_refresh = false;
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async add() {
      this.$v.$touch();

      if (this.$v.title.$anyError
          || this.$v.category.$anyError
          || this.$v.situation.$anyError
          || this.$v.startdate.$anyError
          || this.$v.enddate.$anyError
          || this.$v.starttime.$anyError
          || this.$v.endtime.$anyError
          || this.$v.address.$anyError
          || this.$v.state.$anyError
          || this.$v.city.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'title': this.title,
          'startdate': this.startdate,
          'starttime': this.starttime,
          'enddate': this.enddate,
          'endtime': this.endtime,
          'address': this.address,
          'state': this.state,
          'city': this.city,
          'description': this.description,
          'category': this.category,
          'situation': this.situation,
        };
        this.Api().post('/json/add_event', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.items.unshift({
                id: response.data.id,
                title: this.title,
                startdate: this.formatDate(this.startdate),
                enddate: this.formatDate(this.enddate),
                starttime: this.starttime,
                endtime: this.endtime,
                description: this.description,
                category: this.category,
                situation: this.situation,
                address: this.address,
                state: this.state,
                city: this.city
              });
              this.loading = false;
              this.dialog_add = false;
              this.resetForm();
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);
    },

    async edit(item) {
      this.index = this.items.indexOf(item);
      this.item = Object.assign({}, item);

      this.title = this.item.title;
      this.description = this.item.description;
      this.startdate = parseDate(this.item.startdate);
      this.starttime = this.item.starttime;
      this.enddate = parseDate(this.item.enddate);
      this.endtime = this.item.endtime;
      this.address = this.item.address;
      this.state = this.item.state;
      this.city = this.item.city;
      this.category = this.item.category;
      this.situation = this.item.situation;
      this.dialog_edit = true;
    },

    async save_item() {
      this.$v.$touch();

      if (this.$v.title.$anyError
          || this.$v.category.$anyError
          || this.$v.situation.$anyError
          || this.$v.startdate.$anyError
          || this.$v.enddate.$anyError
          || this.$v.starttime.$anyError
          || this.$v.endtime.$anyError
          || this.$v.address.$anyError
          || this.$v.state.$anyError
          || this.$v.city.$anyError) {
        return;
      }

      console.log(this.state)
      console.log(this.city)

      this.loading = true;

      setTimeout(() => {
        const data = {
          'item': this.item.id,
          'token': this.$store.state.token,
          'title': this.title,
          'startdate': this.startdate,
          'starttime': this.starttime,
          'enddate': this.enddate,
          'endtime': this.endtime,
          'address': this.address,
          'state': this.state,
          'city': this.city,
          'description': this.description,
          'category': this.category,
          'situation': this.situation,
        };

        this.Api().post('/json/save_event', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;

              Object.assign(this.items[this.index], {
                title: this.title,
                description: this.description,
                startdate: this.formatDate(this.startdate),
                starttime: this.starttime,
                endtime: this.endtime,
                enddate: this.formatDate(this.enddate),
                category: this.category,
                situation: this.situation,
                address: this.address,
                state: this.state,
                city: this.city,
              });
              this.loading = false;
              this.dialog_edit = false;
              this.resetForm();
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_edit = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
        });
      }, 3000);
    },

    async deleteItem(item) {
      this.index = this.items.indexOf(item);
      this.item = Object.assign({}, item);
      this.dialog_delete = true;
    },

    async excluir() {
      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': this.item.id,
        };
        this.Api().post('/json/delete_event', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.items.splice(this.index, 1);
              this.loading = false;
              this.dialog_delete = false;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_delete = false;
              break;
          }

        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);

    },

    async upload(item) {
      this.index = this.items.indexOf(item);
      this.item = Object.assign({}, item);
      if (this.items[this.index].image) {
        this.items[this.index].image.map(r => {
          this.description_image.push(r.description);
        });
      }
      if (this.items[this.index].video) {
        this.items[this.index].video.map(r => {
          this.description_video.push(r.title);
        });
      }
      if (this.items[this.index].audio) {
        this.items[this.index].audio.map(r => {
          this.description_audio.push(r.description);
        });
      }
      if (this.items[this.index].file) {
        this.items[this.index].file.map(r => {
          this.description_file.push(r.description);
        });
      }
      this.dialog_upload = true;
    },

    async submit_file() {
      switch (this.submit) {
        case 1:
          this.upload_image();
          break;
        case 2:
          this.upload_video();
          break;
        case 3:
          this.upload_audio();
          break;
        case 4:
          this.upload_file();
          break;
      }
    },

    async upload_image() {
      this.$v.$touch();

      if (this.$v.image.$anyError) {
        return;
      }

      this.loading = true;
      var result = true;

      if (this.image.length > process.env.VUE_APP_FILE_LIMIT) {
        this.snackbar.show = true;
        this.snackbar.text = 'Não é possível enviar mais que ' + process.env.VUE_APP_FILE_LIMIT + ' arquivos por vez.';
        this.loading = false;
        result = false;
      }

      for (var a = 0; a < this.image.length; a++) {
        var size = this.image[a].size;

        if ((size / 1024) > process.env.VUE_APP_SIZE_LIMIT) {
          this.snackbar.show = true;
          this.snackbar.text = 'Não é possível enviar um arquivo com tamanho superior a ' + formatBytes(process.env.VUE_APP_SIZE_LIMIT * 1024);
          this.loading = false;
          result = false;
        }
      }

      if (result) {
        let formData = new FormData();
        for (var i = 0; i < this.image.length; i++) {
          let file = this.image[i];
          formData.append('files[' + i + ']', file);
        }

        formData.append('token', this.$store.state.token);
        formData.append('item', this.item.id);

        setTimeout(() => {
          this.Api().post('/json/send_image_event', formData).then(response => {

            switch (response.data.status) {
              case "invalid":
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                this.$router.push({path: "/login",}).catch(err => {
                  console.log(err)
                });
                break;

              case "success":

                this.$set(this.items[this.index], 'image', response.data.image);
                this.$set(this.item, 'image', response.data.image);

                this.loading = false;
                this.snackbar.show = true;
                this.snackbar.text = response.data.text;
                this.resetForm();
                break;

              default:
                this.snackbar.show = true;
                this.snackbar.text = response.data.text;
                this.loading = false;
                this.resetForm();
                break;
            }

          }).catch(error => {
            this.snackbar.show = true;
            this.snackbar.text = error;
            this.loading = false;
            this.resetForm();
          });
        }, 3000);
      }
    },

    async upload_video() {
      this.$v.$touch();

      if (this.$v.url.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'url': this.url,
          'item': this.item.id
        };
        this.Api().post('/json/send_video_event', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.$set(this.items[this.index], 'video', response.data.video);
              this.$set(this.item, 'video', response.data.video);

              this.loading = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.resetForm();
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);

    },

    async upload_audio() {
      this.$v.$touch();

      if (this.$v.audio.$anyError) {
        return;
      }

      this.loading = true;
      var result = true;

      if (this.audio.length > (process.env.VUE_APP_FILE_LIMIT - 5)) {
        this.snackbar.show = true;
        this.snackbar.text = 'Não é possível enviar mais que ' + (process.env.VUE_APP_FILE_LIMIT - 5) + ' arquivos por vez.';
        this.loading = false;
        result = false;
      }

      for (var a = 0; a < this.audio.length; a++) {
        var size = this.audio[a].size;

        if ((size / 1024) > (process.env.VUE_APP_SIZE_LIMIT + 100000)) {
          this.snackbar.show = true;
          this.snackbar.text = 'Não é possível enviar um arquivo com tamanho superior a ' + formatBytes((process.env.VUE_APP_SIZE_LIMIT + 300000) * 1024);
          this.loading = false;
          result = false;
        }
      }

      if (result) {
        let formData = new FormData();
        for (var i = 0; i < this.audio.length; i++) {
          let file = this.audio[i];
          formData.append('files[' + i + ']', file);
        }

        formData.append('token', this.$store.state.token);
        formData.append('item', this.item.id);

        setTimeout(() => {
          this.Api().post('/json/send_audio_event', formData).then(response => {

            switch (response.data.status) {
              case "invalid":
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                this.$router.push({path: "/login",}).catch(err => {
                  console.log(err)
                });
                break;

              case "success":

                this.$set(this.items[this.index], 'audio', response.data.audio);
                this.$set(this.item, 'audio', response.data.audio);

                this.loading = false;
                this.snackbar.show = true;
                this.snackbar.text = response.data.text;
                this.resetForm();
                break;

              default:
                this.snackbar.show = true;
                this.snackbar.text = response.data.text;
                this.loading = false;
                this.resetForm();
                break;
            }

          }).catch(error => {
            this.snackbar.show = true;
            this.snackbar.text = error;
            this.loading = false;
            this.resetForm();
          });
        }, 3000);
      }
    },

    async upload_file() {
      this.$v.$touch();

      if (this.$v.file.$anyError) {
        return;
      }

      this.loading = true;
      var result = true;

      if (this.file.length > process.env.VUE_APP_FILE_LIMIT) {
        this.snackbar.show = true;
        this.snackbar.text = 'Não é possível enviar mais que ' + process.env.VUE_APP_FILE_LIMIT + ' arquivos por vez.';
        this.loading = false;
        result = false;
      }

      for (var a = 0; a < this.file.length; a++) {
        var size = this.file[a].size;

        if ((size / 1024) > (process.env.VUE_APP_SIZE_LIMIT + 30000)) {
          this.snackbar.show = true;
          this.snackbar.text = 'Não é possível enviar um arquivo com tamanho superior a ' + formatBytes((process.env.VUE_APP_SIZE_LIMIT + 30000) * 1024);
          this.loading = false;
          result = false;
        }
      }

      if (result) {
        let formData = new FormData();
        for (var i = 0; i < this.file.length; i++) {
          let file = this.file[i];
          formData.append('files[' + i + ']', file);
        }

        formData.append('token', this.$store.state.token);
        formData.append('item', this.item.id);

        setTimeout(() => {
          this.Api().post('/json/send_file_event', formData).then(response => {

            switch (response.data.status) {
              case "invalid":
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                this.$router.push({path: "/login",}).catch(err => {
                  console.log(err)
                });
                break;

              case "success":

                this.$set(this.items[this.index], 'file', response.data.file);
                this.$set(this.item, 'file', response.data.file);

                this.loading = false;
                this.snackbar.show = true;
                this.snackbar.text = response.data.text;
                this.resetForm();
                break;

              default:
                this.snackbar.show = true;
                this.snackbar.text = response.data.text;
                this.loading = false;
                this.resetForm();
                break;
            }
          }).catch(error => {
            this.snackbar.show = true;
            this.snackbar.text = error;
            this.loading = false;
            this.resetForm();
          });
        }, 3000);
      }
    },

    async download(data, name) {
      switch (this.submit) {
        case 1:
          this.Api()({
            url: data,
            method: 'GET',
            responseType: 'blob',
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            },
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          });
          break;

        case 3:
          this.Api()({
            url: data,
            method: 'GET',
            responseType: 'blob', // important
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            },
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          });
          break;

        case 4:
          this.Api()({
            url: data,
            method: 'GET',
            responseType: 'blob', // important
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            },
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          });
          break;
      }
    },

    async delete_file(file, item) {
      this.loading_delete = true;

      switch (this.submit) {
        case 1:
          setTimeout(() => {
            const data = {
              'token': this.$store.state.token,
              'item': file.id
            };
            this.Api().post('/json/delete_image_event', data).then(response => {
              switch (response.data.status) {
                case "invalid":
                  localStorage.removeItem('token');
                  localStorage.removeItem('user');
                  this.$router.push({path: "/login",}).catch(err => {
                    console.log(err)
                  });
                  break;

                case "success":
                  this.index = this.items.findIndex(r => item.id === r.id);
                  this.items[this.index].image = this.items[this.index].image.filter(r => {
                    return r.id !== file.id;
                  });
                  this.item.image = this.item.image.filter(r => {
                    return r.id !== file.id;
                  })

                  this.$set(this.items[this.index]);
                  this.$set(this.item);

                  this.loading_delete = false;
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;

                  break;

                default:
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;
                  this.loading_delete = false;
                  break;
              }
            }).catch(error => {
              this.snackbar.show = true;
              this.snackbar.text = error;
              this.loading_delete = false;
            });
          }, 1000);
          break;
        case 2:
          setTimeout(() => {
            const data = {
              'token': this.$store.state.token,
              'item': file.id
            };
            this.Api().post('/json/delete_video_event', data).then(response => {
              switch (response.data.status) {
                case "invalid":
                  localStorage.removeItem('token');
                  localStorage.removeItem('user');
                  this.$router.push({path: "/login",}).catch(err => {
                    console.log(err)
                  });
                  break;

                case "success":
                  this.index = this.items.findIndex(r => item.id === r.id);
                  this.items[this.index].video = this.items[this.index].video.filter(r => {
                    return r.id !== file.id;
                  });
                  this.item.video = this.item.video.filter(r => {
                    return r.id !== file.id;
                  })

                  this.$set(this.items[this.index]);
                  this.$set(this.item);

                  this.loading_delete = false;
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;

                  break;

                default:
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;
                  this.loading_delete = false;
                  break;
              }
            }).catch(error => {
              this.snackbar.show = true;
              this.snackbar.text = error;
              this.loading_delete = false;
            });
          }, 1000);
          break;
        case 3:
          setTimeout(() => {
            const data = {
              'token': this.$store.state.token,
              'item': file.id
            };
            this.Api().post('/json/delete_audio_event', data).then(response => {
              switch (response.data.status) {
                case "invalid":
                  localStorage.removeItem('token');
                  localStorage.removeItem('user');
                  this.$router.push({path: "/login",}).catch(err => {
                    console.log(err)
                  });
                  break;

                case "success":
                  this.index = this.items.findIndex(r => item.id === r.id);
                  this.items[this.index].audio = this.items[this.index].audio.filter(r => {
                    return r.id !== file.id;
                  });
                  this.item.audio = this.item.audio.filter(r => {
                    return r.id !== file.id;
                  })

                  this.$set(this.items[this.index]);
                  this.$set(this.item);

                  this.loading_delete = false;
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;

                  break;

                default:
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;
                  this.loading_delete = false;
                  break;
              }
            }).catch(error => {
              this.snackbar.show = true;
              this.snackbar.text = error;
              this.loading_delete = false;
            });
          }, 1000);
          break;
        case 4:
          setTimeout(() => {
            const data = {
              'token': this.$store.state.token,
              'item': file.id
            };
            this.Api().post('/json/delete_file_event', data).then(response => {
              switch (response.data.status) {
                case "invalid":
                  localStorage.removeItem('token');
                  localStorage.removeItem('user');
                  this.$router.push({path: "/login",}).catch(err => {
                    console.log(err)
                  });
                  break;

                case "success":
                  this.index = this.items.findIndex(r => item.id === r.id);
                  this.items[this.index].file = this.items[this.index].file.filter(r => {
                    return r.id !== file.id;
                  });
                  this.item.file = this.item.file.filter(r => {
                    return r.id !== file.id;
                  })

                  this.$set(this.items[this.index]);
                  this.$set(this.item);

                  this.loading_delete = false;
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;

                  break;

                default:
                  this.snackbar.show = true;
                  this.snackbar.text = response.data.text;
                  this.loading_delete = false;
                  break;
              }
            }).catch(error => {
              this.snackbar.show = true;
              this.snackbar.text = error;
              this.loading_delete = false;
            });
          }, 1000);
          break;
      }
    },

    async cover_image(file, item) {
      this.loading_cover = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': file.id,
          'event': item.id
        };

        this.Api().post('/json/cover_image_event', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.items.findIndex(r => item.id === r.id);
              var i = item.image.findIndex(r => file.id === r.id);
              this.items[this.index].image.map(r => {
                this.$set(r, 'cover', 'N');
              });
              this.$set(this.items[this.index].image[i], 'cover', 'S');
              this.$set(this.item.image[i], 'cover', 'S');

              this.loading_cover = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_cover = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_cover = false;
        });
      }, 1000);

    },

    async description_file_image(file, item) {
      const i = item.image.findIndex(r => file.id === r.id);

      const data = {
        'token': this.$store.state.token,
        'item': file.id,
        'event': item.id,
        'description': this.description_image[i]
      };
      await this.Api().post('/json/description_image_event', data).then(response => {
        switch (response.data.status) {
          case "invalid":
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.$router.push({path: "/login",}).catch(err => {
              console.log(err)
            });
            break;

          case "success":
            this.index = this.items.findIndex(r => item.id === r.id);
            this.$set(this.items[this.index].image[i], 'description', this.description_image[i]);
            this.$set(this.item.image[i], 'description', this.description_image[i]);

            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;

          default:
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;
        }
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
      });

    },

    async description_file_video(file, item) {
      const i = item.video.findIndex(r => file.id === r.id);

      const data = {
        'token': this.$store.state.token,
        'item': file.id,
        'event': item.id,
        'description': this.description_video[i]
      };
      await this.Api().post('/json/description_video_event', data).then(response => {
        switch (response.data.status) {
          case "invalid":
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.$router.push({path: "/login",}).catch(err => {
              console.log(err)
            });
            break;

          case "success":
            this.index = this.items.findIndex(r => item.id === r.id);
            this.$set(this.items[this.index].video[i], 'titulo', this.description_video[i]);
            this.$set(this.item.video[i], 'titulo', this.description_video[i]);

            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;

          default:
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;
        }
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
      });

    },

    async description_file_audio(file, item) {
      const i = item.audio.findIndex(r => file.id === r.id);

      const data = {
        'token': this.$store.state.token,
        'item': file.id,
        'event': item.id,
        'description': this.description_audio[i]
      };
      await this.Api().post('/json/description_audio_event', data).then(response => {
        switch (response.data.status) {
          case "invalid":
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.$router.push({path: "/login",}).catch(err => {
              console.log(err)
            });
            break;

          case "success":
            this.index = this.items.findIndex(r => item.id === r.id);
            this.$set(this.items[this.index].audio[i], 'description', this.description_audio[i]);
            this.$set(this.item.audio[i], 'description', this.description_audio[i]);

            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;

          default:
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;
        }
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
      });

    },

    async description_file_file(file, item) {
      const i = item.file.findIndex(r => file.id === r.id);

      const data = {
        'token': this.$store.state.token,
        'item': file.id,
        'event': item.id,
        'description': this.description_file[i]
      };
      await this.Api().post('/json/description_file_event', data).then(response => {
        switch (response.data.status) {
          case "invalid":
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.$router.push({path: "/login",}).catch(err => {
              console.log(err)
            });
            break;

          case "success":
            this.index = this.items.findIndex(r => item.id === r.id);
            this.$set(this.items[this.index].file[i], 'description', this.description_file[i]);
            this.$set(this.item.file[i], 'description', this.description_file[i]);

            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;

          default:
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            break;
        }
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
      });

    },

    async featured_item(item, r) {
      this.loading_featured = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': item.id,
          'featured': r
        };
        this.Api().post('/json/featured_event', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.items.findIndex(r => item.id === r.id);
              this.$set(this.items[this.index], 'featured', r === 'S' ? true : false);
              this.$set(this.item, 'featured', r === 'S' ? true : false);

              this.loading_featured = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_featured = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_featured = false;
        });
      }, 1000);
    },

    async situation_item(item, r) {
      this.loading_situation = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': item.id,
          'situation': r
        };
        this.Api().post('/json/situation_event', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.items.findIndex(r => item.id === r.id);
              this.$set(this.items[this.index].situation, 'id', r === 'S' ? 'S' : 'N');

              this.loading_situation = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_situation = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_situation = false;
        });
      }, 1000);
    },

    async search_category() {
      await this.Api().post('/json/search_event_categories', {
        'token': this.$store.state.token,
      }).then(response => {
        this.categories = response.data;
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    resetForm() {
      this.title = '';
      this.address = '';
      this.startdate = '';
      this.starttime = '';
      this.enddate = '';
      this.endtime = '';
      this.description = '';
      this.state = '';
      this.city = '';
      this.description_audio = '';
      this.description_image = '';
      this.description_file = '';
      this.description_video = '';
      this.category = '';
      this.situation = '';
      this.file = [];
      this.image = [];
      this.audio = [];
      this.url = '';
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  }
}
</script>
