<template>
    <div class="page-safety">
        <Topo :back="true" :notification="true" :plans="true"/>
        <v-main>
            <v-container class="fill-height" fluid>
                <v-row>
                    <v-col>
                        <v-card class="card-section" outlined>
                            <div class="topo-card">
                                <v-row dense>
                                    <v-col cols>
                                        <v-toolbar-title>Perfil</v-toolbar-title>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-card-text>
                                <v-row class="align-center">

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <Footer/>
    </div>
</template>

<script>
    import Topo from "../../components/Topo";
    import Footer from "../../components/Footer";
    import {load} from 'recaptcha-v3';

    export default {
        name: "Usuarios",
        components: {Topo, Footer},
        data: () => ({
            siteKey: process.env.VUE_APP_RECAPTCHA,
            seguranca: [],
            loading: false,
            loading_check: false,
            snackbar: {
                show: false,
                text: "",
                timeout: 7000,
            },
        }),
        created() {
            //this.initialize();
        },
        methods: {
            async initialize() {
                await this.Api().post('/json/searcher_situation', {
                    'token': this.$store.state.token,
                }).then(response => {
                    this.seguranca = response.data;
                }).catch(error => {
                    this.snackbar.show = true;
                    this.snackbar.text = error;
                    this.loading = false;
                });
            },

            async change_password() {
                this.loading = true;
                const recaptcha = await load(this.siteKey, {useRecaptchaNet: true, autoHideBadge: true});
                const token = await recaptcha.execute('login');

                setTimeout(() => {
                    const data = {
                        'token': this.$store.state.token,
                        'g-recaptcha-response': token,
                    };
                    this.Api().post(  '/json/change_password_user', data).then(response => {
                        if (response.data.status === "success") {
                            this.snackbar.show = true;
                            this.snackbar.text = response.data.text;
                            this.loading = false;
                        } else {
                            this.snackbar.show = true;
                            this.snackbar.text = response.data.text;
                            this.loading = false;
                        }
                    }).catch(error => {
                        this.snackbar.show = true;
                        this.snackbar.text = error;
                        this.loading = false;
                    });
                }, 3000);
            },

            async check_email() {
                this.loading_check = true;
                const recaptcha = await load(this.siteKey, {useRecaptchaNet: true, autoHideBadge: true});
                const token = await recaptcha.execute('login');

                setTimeout(() => {
                    const data = {
                        'token': this.$store.state.token,
                        'g-recaptcha-response': token,
                    };
                    this.Api().post( '/json/check_email', data).then(response => {
                        if (response.data.status === "success") {
                            this.snackbar.show = true;
                            this.snackbar.text = response.data.text;
                            this.loading_check = false;
                        } else {
                            this.snackbar.show = true;
                            this.snackbar.text = response.data.text;
                            this.loading_check = false;
                        }
                    }).catch(error => {
                        this.snackbar.show = true;
                        this.snackbar.text = error;
                        this.loading_check = false;
                    });
                }, 3000);

            }
        },
    }
</script>

<style lang="scss">
    .page-safety {

        .card-section {
            .section-subtitle {
                font-size: 20px;
                font-weight: bold;
            }

            .data-status {
                display: flex;

                .shield {
                    margin-right: 20px;
                    .active {
                        .v-icon {
                            font-size: 100px !important;
                        }
                    }

                    .inactive {
                        .v-icon {
                            font-size: 100px !important;
                        }
                    }
                }

                .data {
                    .btn-check {
                        text-transform: none !important;
                        background-color: var(--cor-principal) !important;
                    }

                    .active {
                        color: green;
                    }

                    .inactive {
                        color: red;
                    }
                }

            }


            .btn-change {
                text-transform: none !important;
                background-color: var(--cor-segundaria) !important;
            }
        }
    }

</style>


