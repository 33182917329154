<template>
  <div class="page-user page">
    <Topo :back="true" :notification="true" :plans="true"/>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row>
          <v-col>
            <v-card class="card-section" outlined>
              <v-data-table :options="{ itemsPerPage: 10, }"
                            no-data-text="Nenhum produto encontrado"
                            :footer-props="{'items-per-page-options':[5,10, 20, 100, -1],'items-per-page-all-text':'Todos','items-per-page-text':'Itens por página',}"
                            :headers="headers"
                            :search="search"
                            :items="usuarios"
                            sort-by="name"
                            class="elevation-1">

                <template v-slot:item.situation="{ item }">
                  <v-btn
                      depressed
                      :class="item.situation.id === 1 ? 'btn-active' : 'btn-not-active'"
                      :disabled="loading_situation && item.id === situation_index"
                      :loading="loading_situation && item.id === situation_index"
                      @click="situation_item(item, item.situation.id === 1 ? 2 : 1); situation_index = item.id">
                    {{ item.situation.id === 1 ? 'Ativo' : 'Inativo' }}
                  </v-btn>
                </template>

                <template v-slot:item.perfil="{ item }">
                  <v-btn icon :to="`/perfil/${item.id}`">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </template>

                <template v-slot:top>
                  <div class="topo-card">
                    <v-row dense>
                      <v-col md="4" cols>
                        <v-toolbar-title>Lista de usuários</v-toolbar-title>
                      </v-col>
                      <v-col md="8" cols>
                        <div class="d-flex">
                          <v-text-field
                              v-model="search"
                              class="search-card"
                              append-icon="mdi-magnify"
                              label="Buscar..."
                              solo
                              flat
                              hide-details
                          ></v-text-field>
                          <v-dialog v-model="dialog_add" max-width="500px" scrollable>
                            <v-card>
                              <v-card-title>
                                <span class="card-title">Cadastrar</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       v-on:click="resetForm()"
                                       @click="dialog_add = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-text-field
                                      v-model="name"
                                      :error-messages="nameErrors"
                                      label="Nome completo"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.name.$touch()"
                                      @blur="$v.name.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="phone"
                                      :error-messages="phoneErrors"
                                      label="Telefone"
                                      v-mask="['(##) ####-####', '(##) #####-####']"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.phone.$touch()"
                                      @blur="$v.phone.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="email"
                                      :error-messages="emailErrors"
                                      label="E-mail"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.email.$touch()"
                                      @blur="$v.email.$touch()"
                                  ></v-text-field>
                                  <v-select
                                      v-model="situation"
                                      :items="items"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="situationErrors"
                                      label="Situação"
                                      outlined
                                      required
                                      return-object
                                      dense
                                      clearable
                                      @input="$v.situation.$touch()"
                                      @blur="$v.situation.$touch()"
                                  ></v-select>

                                  <v-text-field
                                      v-model="password"
                                      :error-messages="passwordErrors"
                                      label="Senha"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                      :type="show ? 'text' : 'password'"
                                      autocomplete="on"
                                      @click:append="show = !show"
                                      @input="$v.password.$touch()"
                                      @blur="$v.password.$touch()"
                                  ></v-text-field>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="add()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_edit" max-width="500px" scrollable>
                            <v-card>
                              <v-card-title>
                                <span class="card-title">Editar</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       @click="dialog_edit = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-text-field
                                      v-model="name"
                                      :error-messages="nameErrors"
                                      label="Nome completo"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.name.$touch()"
                                      @blur="$v.name.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="phone"
                                      :error-messages="phoneErrors"
                                      label="Telefone"
                                      v-mask="['(##) ####-####', '(##) #####-####']"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.phone.$touch()"
                                      @blur="$v.phone.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="email"
                                      :error-messages="emailErrors"
                                      label="E-mail"
                                      required
                                      disabled
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.email.$touch()"
                                      @blur="$v.email.$touch()"
                                  ></v-text-field>
                                  <v-select
                                      v-model="situation"
                                      :items="items"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="situationErrors"
                                      label="Situação"
                                      outlined
                                      required
                                      return-object
                                      dense
                                      clearable
                                      @input="$v.situation.$touch()"
                                      @blur="$v.situation.$touch()"
                                  ></v-select>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="save_user()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_password" max-width="500px">
                            <v-card>
                              <v-card-title>
                                <span class="card-title">Trocar senha</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       @click="dialog_password = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-text-field
                                      v-model="name"
                                      :error-messages="nameErrors"
                                      label="Nome completo"
                                      required
                                      outlined
                                      disabled
                                      clearable
                                      dense
                                      @input="$v.name.$touch()"
                                      @blur="$v.name.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="email"
                                      :error-messages="emailErrors"
                                      label="E-mail"
                                      required
                                      outlined
                                      clearable
                                      disabled
                                      dense
                                      @input="$v.email.$touch()"
                                      @blur="$v.email.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="password"
                                      :error-messages="passwordErrors"
                                      label="Senha"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                      :type="show ? 'text' : 'password'"
                                      autocomplete="on"
                                      @click:append="show = !show"
                                      @input="$v.password.$touch()"
                                      @blur="$v.password.$touch()"
                                  ></v-text-field>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="save_password()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_permission" max-width="500px" scrollable>
                            <v-card>
                              <v-card-title>
                                <span class="card-title">Permissão</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       @click="dialog_permission = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-switch
                                      v-for="(m, index) in levar"
                                      v-model="checked"
                                      :key="index"
                                      :value="m.id"
                                      :label="m.descricao"
                                      hide-details
                                      dense
                                  ></v-switch>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="save_permission()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn dark depressed class="btn-add" v-bind="attrs"
                                     v-on="on"
                                     @click="resetForm(); dialog_add = true">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Cadastrar</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs" v-on="on"
                                  depressed
                                  color="primary"
                                  class="btn-refresh"
                                  :loading="loading_refresh"
                                  :disabled="loading_refresh"
                                  @click="initialize">
                                <v-icon>mdi-refresh</v-icon>
                              </v-btn>
                            </template>
                            <span>Recarregar</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-menu class="menu-list" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link @click="edit(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-account-edit-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item link @click="passwords(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-account-key-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Trocar senha</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item link @click="permission(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-account-lock-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Permissão</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>

                <template v-slot:no-data>
                  <v-btn :loading="loading_refresh"
                         :disabled="loading_refresh"
                         color="primary" depressed @click="initialize">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <Footer/>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, minLength, maxLength, email} from 'vuelidate/lib/validators';
import {mask} from "vue-the-mask";

import Topo from "../../components/Topo";
import Footer from "../../components/Footer";

export default {
  name: "Usuarios",
  components: {Topo, Footer},
  mixins: [validationMixin],
  directives: {mask},
  data: () => ({
    search: '',
    headers: [
      {text: 'Perfil', value: 'perfil', sortable: false},
      {
        text: 'Nome',
        align: 'start',
        value: 'name',
      },
      {text: 'Telefone', value: 'phone'},
      {text: 'E-mail', value: 'email'},
      {text: 'Situação', value: 'situation', sortable: false},
      {text: 'Ação', value: 'actions', sortable: false},
    ],
    usuarios: [],
    levar: [],
    checked: [],
    loading: false,
    loading_refresh: false,
    dialog_add: false,
    dialog_edit: false,
    dialog_password: false,
    dialog_permission: false,
    loading_situation: false,
    situation_index: -1,
    show: false,
    snackbar: {
      show: false,
      text: "",
      timeout: 7000,
    },
    user: {},
    index: -1,
    name: '',
    phone: '',
    email: '',
    password: '',
    situation: '',
    items: [{id: 1, description: "Ativo"}, {id: 2, description: "Inativo"}]
  }),
  validations: {
    name: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(100)
    },
    phone: {
      required,
      minLength: minLength(13),
      maxLength: maxLength(15)
    },
    email: {
      required, email,
      maxLength: maxLength(100)
    },
    password: {
      required,
      minLength: minLength(6),
      containsUppercase: function (value) {
        return /[A-Z]/.test(value)
      },
      containsLowercase: function (value) {
        return /[a-z]/.test(value)
      },
      containsNumber: function (value) {
        return /[0-9]/.test(value)
      },
      containsSpecial: function (value) {
        return /[#?!@$%^&*-]/.test(value)
      }
    },
    situation: {
      required
    },
  },

  async created() {
    await this.initialize();
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.minLength && errors.push('O nome deve ter no mínino 5 caracteres.');
      !this.$v.name.maxLength && errors.push('O nome deve ter no máximo 100 caracteres.');
      !this.$v.name.required && errors.push('Nome é obrigatório.');
      return errors
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.minLength && errors.push('O telefone deve ter no mínino 8 caracteres.');
      !this.$v.name.maxLength && errors.push('O telefone deve ter no máximo 11 caracteres.');
      !this.$v.phone.required && errors.push('Telefone é obrigatório.');
      return errors
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Deve ser um e-mail válido.');
      !this.$v.name.maxLength && errors.push('O nome deve ter no máximo 100 caracteres.');
      !this.$v.email.required && errors.push('E-mail é obrigatório.');
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Senha é obrigatório.')
      !this.$v.password.containsUppercase && errors.push('Senha deve conter no mínimo 1 caracteres maiúsculo.')
      !this.$v.password.containsLowercase && errors.push('Senha deve conter no mínimo 1 caracteres minúsculo.')
      !this.$v.password.containsNumber && errors.push('Senha deve conter no mínimo 1 número.')
      !this.$v.password.containsSpecial && errors.push('Senha deve conter no mínimo 1 caracteres especial.')
      !this.$v.password.minLength && errors.push('Senha deve conter no mínimo 6 caracteres.')
      return errors
    },
    situationErrors() {
      const errors = []
      if (!this.$v.situation.$dirty) return errors
      !this.$v.situation.required && errors.push('Situação é obrigatório.')
      return errors
    },
  },

  methods: {
    async initialize() {
      this.loading_refresh = true;
      await this.Api().post('/json/searcher_users', {
        'token': this.$store.state.token,
      }).then(response => {
        this.usuarios = response.data;
        this.loading_refresh = false;
      }).catch(error => {
        this.loading_refresh = false;
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async add() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'name': this.name,
          'phone': this.phone,
          'email': this.email,
          'situation': this.situation,
          'password': this.password
        };
        this.Api().post('/json/add_user', data).then(response => {
          if (response.data.status === "success") {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.usuarios.push({
              id: response.data.id,
              name: this.name,
              phone: this.phone,
              email: this.email,
              situation: this.situation
            });
            this.loading = false;
            this.dialog_add = false;
            this.resetForm();
          } else {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading = false;
            this.dialog_add = false;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);
    },

    async edit(item) {
      this.index = this.usuarios.indexOf(item);
      this.user = Object.assign({}, item);

      this.name = this.user.name;
      this.phone = this.user.phone;
      this.email = this.user.email;
      this.situation = this.user.situation;
      this.dialog_edit = true;
    },

    async save_user() {

      this.$v.$touch();
      if (this.$v.situation.$anyError || this.$v.email.$anyError || this.$v.phone.$anyError || this.$v.name.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'user': this.user.id,
          'name': this.name,
          'phone': this.phone,
          'email': this.email,
          'situation': this.situation
        };

        this.Api().post('/json/save_user', data).then(response => {
          if (response.data.status === "success") {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;

            Object.assign(this.usuarios[this.index], {
              name: this.name,
              phone: this.phone,
              email: this.email,
              situation: this.situation
            });
            this.loading = false;
            this.dialog_edit = false;
            this.resetForm();
          } else {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading = false;
            this.dialog_edit = false;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
        });
      }, 3000);
    },

    async passwords(item) {
      this.user = Object.assign({}, item)
      this.name = this.user.name;
      this.email = this.user.email;
      this.dialog_password = true;
    },

    async save_password() {
      this.$v.$touch();
      if (this.$v.password.$anyError || this.$v.email.$anyError || this.$v.name.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'user': this.user.id,
          'password': this.password
        };

        this.Api().post('/json/save_password_user', data).then(response => {
          if (response.data.status === "success") {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading = false;
            this.dialog_password = false;
            this.resetForm();
          } else {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading = false;
            this.dialog_password = false;
            this.resetForm();
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.dialog_password = false;
          this.resetForm();
        });
      }, 3000);
    },

    async permission(item) {
      this.user = Object.assign({}, item)
      this.dialog_permission = true

      await this.Api().post('/json/edit_permission', {
        'token': this.$store.state.token,
        'user': this.user.id,
      }).then(response => {
        this.levar = response.data;
        this.checked = response.data.map(r => {
          if (r.checked === true) {
            return r.id;
          }
        });
      }).catch(error => {
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async save_permission() {
      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'checked': this.checked,
          'user': this.user.id
        };
        this.Api().post('/json/save_permission', data).then(response => {
          if (response.data.status === "success") {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading = false;
            this.dialog_permission = false;
            this.$children[0].modulos();
            this.resetForm();
          } else {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading = false;
            this.dialog_permission = false;
            this.resetForm();
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_permission = false;
          this.resetForm();
        });
      }, 3000);
    },

    async situation_item(item, r) {
      this.loading_situation = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': item.id,
          'situation': r
        };
        this.Api().post('/json/situation_user', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.usuarios.findIndex(r => item.id === r.id);
              this.$set(this.usuarios[this.index].situation, 'id', r === 1 ? 1 : 2);

              this.loading_situation = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_situation = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_situation = false;
        });
      }, 1000);
    },

    resetForm() {
      this.name = '';
      this.phone = '';
      this.email = '';
      this.password = '';
      this.situation = '';
      this.checked = [];
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
}
</script>

<style lang="scss">
.page-user {
  .card-section {
    .topo-card {
      height: 80px;

      .search-card {
        .v-input__control {
          min-height: 40px !important;

          .v-input__slot {
            background: var(--light-gray) !important;
          }
        }

      }

      .btn-add {
        height: 40px !important;
        background-color: var(--cor-segundaria) !important;
      }
    }
  }
}

</style>


