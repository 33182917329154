import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') ? localStorage.getItem('token') : false,
        user: localStorage.getItem('user') ? localStorage.getItem('user') : false,
        domain: sessionStorage.getItem('domain') ? sessionStorage.getItem('domain') : false,
        modulos: sessionStorage.getItem('modulos') ? sessionStorage.getItem('modulos') : false,
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            localStorage.setItem('token', token)
        },
        setUser(state, user) {
            state.user = user
            localStorage.setItem('user', user)
        },
        setDomain(state, domain) {
            state.domain = domain
            sessionStorage.setItem('domain', domain)
        },
        setModulos(state, modulos) {
            state.modulos = modulos
            sessionStorage.setItem('modulos', modulos)
        },
        logout(state){
            state.token = false
            state.user = false
            localStorage.removeItem('token');
            localStorage.removeItem('user');
        }
    },
    actions: {},
    modules: {}
})
