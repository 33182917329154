<template>
    <div class="page-recoverypass">
        <v-container>
            <v-row v-if="error">
                <v-col>
                    <div class="key-invalid">
                        <v-icon>mdi-key-variant</v-icon>
                        <div class="title-card">Chave de recuperação inválida.</div>
                        <v-btn class="btn-recoverypass" depressed large to="/esqueceu-senha">Recuperar senha</v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    <v-card class="mx-auto pa-5" max-width="450" elevation="5">
                        <v-card-text>
                            <v-card-title>Recuperação de senha</v-card-title>
                            <div class="text-card pa-2">Digite sua nova senha</div>
                            <v-card-actions>
                                <form class="form-recoverypass">
                                    <v-text-field
                                            v-model="name"
                                            :error-messages="nameErrors"
                                            label="Nome"
                                            required
                                            outlined
                                            disabled
                                            @input="$v.name.$touch()"
                                            @blur="$v.name.$touch()"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="email"
                                            :error-messages="emailErrors"
                                            label="E-mail"
                                            required
                                            outlined
                                            disabled
                                            @input="$v.email.$touch()"
                                            @blur="$v.email.$touch()"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="password"
                                            :error-messages="passwordErrors"
                                            label="Senha"
                                            required
                                            outlined
                                            clearable
                                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="show ? 'text' : 'password'"
                                            autocomplete="on"
                                            @click:append="show = !show"
                                            @input="$v.password.$touch()"
                                            @blur="$v.password.$touch()"
                                    ></v-text-field>
                                    <div>
                                        <v-btn class="btn-submit"
                                               :loading="loading"
                                               :disabled="loading"
                                               depressed
                                               large
                                               @click="submit()">
                                            Enviar
                                        </v-btn>
                                    </div>
                                </form>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <Footer/>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate';
    import {required, email, minLength} from 'vuelidate/lib/validators';
    import Footer from "../components/Footer";
    import {load} from 'recaptcha-v3';

    export default {
        components: {Footer},

        data: () => ({
            siteKey: process.env.VUE_APP_RECAPTCHA,
            loading: false,
            user: '',
            name: '',
            email: '',
            password: '',
            show: false,
            error: true,
            snackbar: {
                show: false,
                text: "",
                timeout: 3000,
            },
        }),

        mixins: [validationMixin],

        validations: {
            name: {
                required,
                minLength: minLength(6)
            },
            password: {
                required,
                minLength: minLength(6),
                containsUppercase: function (value) {
                    return /[A-Z]/.test(value)
                },
                containsLowercase: function (value) {
                    return /[a-z]/.test(value)
                },
                containsNumber: function (value) {
                    return /[0-9]/.test(value)
                },
                containsSpecial: function (value) {
                    return /[#?!@$%^&*-]/.test(value)
                }
            },
            email: {required, email},
        },

        computed: {
            nameErrors() {
                const errors = [];
                if (!this.$v.name.$dirty) return errors;
                !this.$v.name.minLength && errors.push('O nome deve ter no mínino 6 caracteres.');
                !this.$v.name.required && errors.push('Nome é obrigatório.');
                return errors
            },
            emailErrors() {
                const errors = [];
                if (!this.$v.email.$dirty) return errors;
                !this.$v.email.email && errors.push('Deve ser um e-mail válido.');
                !this.$v.email.required && errors.push('E-mail é obrigatório.');
                return errors
            },
            passwordErrors() {
                const errors = []
                if (!this.$v.password.$dirty) return errors
                !this.$v.password.required && errors.push('Senha é obrigatório.')
                !this.$v.password.containsUppercase && errors.push('Senha deve conter no mínimo 1 caracteres maiúsculo.')
                !this.$v.password.containsLowercase && errors.push('Senha deve conter no mínimo 1 caracteres minúsculo.')
                !this.$v.password.containsNumber && errors.push('Senha deve conter no mínimo 1 número.')
                !this.$v.password.containsSpecial && errors.push('Senha deve conter no mínimo 1 caracteres especial.')
                !this.$v.password.minLength && errors.push('Senha deve conter no mínimo 6 caracteres.')
                return errors
            },
        },

        created() {
            this.initialize(this.$route.params.key);
            console.log(this.$route.params.key)
        },

        methods: {
            async initialize(key) {
                this.Api().post('/json/recoverypass', {
                    'key': key,
                }).then(response => {

                    if (response.data.status === "success") {
                        this.name = response.data.name;
                        this.email = response.data.email;
                        this.user = response.data.id;
                        this.error = false;
                    } else {
                        this.snackbar.show = true;
                        this.snackbar.text = response.data.text
                        this.error = true;
                    }
                }).catch(error => {
                    this.snackbar.show = true;
                    this.snackbar.text = error;
                    this.error = true;
                });
            },

            async submit() {
                this.$v.$touch();

                if (this.$v.$anyError) {
                    return;
                }

                this.loading = true;
                const recaptcha = await load(this.siteKey, {useRecaptchaNet: true, autoHideBadge: true});
                const token = await recaptcha.execute('login');

                setTimeout(() => {
                    const data = {
                        'user': this.user,
                        'password': this.password,
                        'g-recaptcha-response': token,
                    };
                    this.Api().post('/json/recoverypass_password', data).then(response => {
                        if (response.data.status === "success") {
                            this.snackbar.show = true;
                            this.snackbar.text = response.data.text;
                            this.loading = false;
                            this.resetForm();

                            this.$router.push({path: "/login",}).catch(err => {
                                console.log(err)
                            });
                        } else {
                            this.snackbar.show = true;
                            this.snackbar.text = response.data.text;
                            this.loading = false;
                        }

                    }).catch(error => {
                        this.snackbar.show = true;
                        this.snackbar.text = error;
                        this.loading = false;
                        this.resetForm();
                    });
                }, 3000);
            },

            resetForm() {
                this.email = "";
                this.password = "";
                this.name = "";
                this.user = "";
                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },

        },
    }
</script>

<style lang="scss">
    .page-recoverypass {
        height: 100%;
        background-color: var(--cor-principal);

        .form-recoverypass {
            width: 100%;

            .link {
                text-decoration: none;
                color: rgba(0, 0, 0, .6);
                transition: all .2s ease-in;

                &:hover, &:active, &:focus {
                    color: var(--cor-segundaria);
                }
            }


            .btn-submit {
                margin-top: 10px;
                width: 100%;
                color: var(--white) !important;
                background-color: var(--cor-principal) !important;
            }
        }

        .key-invalid{
            text-align: center;
            color: var(--white);
            .v-icon{
                color: var(--white);
                font-size: 46px!important;
                margin-bottom: 20px;
            }

            .btn-recoverypass{
                margin-top: 20px;
                color: var(--cor-principal)!important;
                background-color: var(--cor-segundaria)!important;
            }
        }
    }
</style>
