<template>
  <div class="page-departments page">
    <Topo :back="true" :notification="true" :plans="true"/>
    <v-main>
      <v-container class="fill-height" fluid>
        <!--<v-row v-if="reports">
            <v-col md="4">
              <v-card class="card-quantity" outlined>
                  <v-card-text>
                    <div class="title-card">Apoiadores</div>
                    <div class="value-card">{{reports.quantity}}</div>
                  </v-card-text>
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card class="card-reports" outlined>
                <v-card-text>
                  <div class="title-card">Alocados no servidor</div>
                  <v-progress-linear v-model="reports.average" height="18">
                    <strong>{{ reports.average.toFixed(3).replace('.', ',') }}%</strong>
                  </v-progress-linear>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card class="card-reports" outlined>
                <v-card-text>
                  <div class="title-card">Espaço total no servidor</div>
                  <v-progress-linear v-model="reports.average" height="18">
                    <strong>{{ reports.average.toFixed(3).replace('.', ',')}}%</strong>
                  </v-progress-linear>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>-->
        <v-row>
          <v-col>
            <v-card class="card-section" outlined>
              <v-data-table :options="{ itemsPerPage: 10, }"
                            no-data-text="Nenhum produto encontrado"
                            :footer-props="{'items-per-page-options':[5,10, 20, 100, -1],'items-per-page-all-text':'Todos','items-per-page-text':'Itens por página',}"
                            :headers="headers"
                            :search="search"
                            :items="items"
                            sort-by="nome"
                            class="elevation-1">

                <template v-slot:item.situation="{ item }">
                  <v-btn
                      depressed
                      :class="item.situation.id === 'S' ? 'btn-active' : 'btn-not-active'"
                      :disabled="loading_situation  && item.id === situation_index"
                      :loading="loading_situation  && item.id === situation_index"
                      @click="situation_item(item, item.situation.id === 'S' ? 'N' : 'S'); situation_index = item.id">
                    {{ item.situation.id === 'S' ? 'Sim' : 'Não' }}
                  </v-btn>
                </template>

                <template v-slot:top>
                  <div class="topo-card">
                    <v-row dense>
                      <v-col md="4" sm="5" cols>
                        <div class="d-flex">
                          <v-toolbar-title>Lista de setores</v-toolbar-title>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon
                                     v-bind="attrs"
                                     v-on="on"
                                     class="ml-3"
                                     @click="dialog_info = true">
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Informação</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                      <v-col md="8" sm="7" cols>
                        <div class="d-flex">
                          <v-text-field
                              v-model="search"
                              class="search-card"
                              append-icon="mdi-magnify"
                              label="Buscar..."
                              solo
                              flat
                              hide-details
                          ></v-text-field>
                          <v-dialog v-model="dialog_add" max-width="500px" scrollable>
                            <v-card>
                              <v-card-title>
                                <span class="card-title">Cadastrar</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       v-on:click="resetForm()"
                                       @click="dialog_add = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-text-field
                                      v-model="description"
                                      :error-messages="descriptionErrors"
                                      label="Setor"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.description.$touch()"
                                      @blur="$v.description.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="email"
                                      :error-messages="emailErrors"
                                      label="E-mail"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.email.$touch()"
                                      @blur="$v.email.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="phone"
                                      :error-messages="phoneErrors"
                                      label="Telefone"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      v-mask="['(##) ####-####', '(##) #####-####']"
                                      @input="$v.phone.$touch()"
                                      @blur="$v.phone.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="whatsapp"
                                      :error-messages="whatsappErrors"
                                      label="WhatsApp"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      v-mask="['(##) ####-####', '(##) #####-####']"
                                      @input="$v.whatsapp.$touch()"
                                      @blur="$v.whatsapp.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="responsible"
                                      label="Responsável"
                                      outlined
                                      clearable
                                      dense
                                  ></v-text-field>
                                  <v-select
                                      v-model="situation"
                                      :items="visivel"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="situationErrors"
                                      label="Visível"
                                      outlined
                                      required
                                      return-object
                                      dense
                                      clearable
                                      @input="$v.situation.$touch()"
                                      @blur="$v.situation.$touch()"
                                  ></v-select>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="add()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_edit" max-width="500px" scrollable>
                            <v-card>
                              <v-card-title>
                                <span class="card-title">Editar</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="blue darken-1"
                                       @click="dialog_edit = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <form>
                                  <v-text-field
                                      v-model="description"
                                      :error-messages="descriptionErrors"
                                      label="Setor"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.description.$touch()"
                                      @blur="$v.description.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="email"
                                      :error-messages="emailErrors"
                                      label="E-mail"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      @input="$v.email.$touch()"
                                      @blur="$v.email.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="phone"
                                      :error-messages="phoneErrors"
                                      label="Telefone"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      v-mask="['(##) ####-####', '(##) #####-####']"
                                      @input="$v.phone.$touch()"
                                      @blur="$v.phone.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="whatsapp"
                                      :error-messages="whatsappErrors"
                                      label="WhatsApp"
                                      required
                                      outlined
                                      clearable
                                      dense
                                      v-mask="['(##) ####-####', '(##) #####-####']"
                                      @input="$v.whatsapp.$touch()"
                                      @blur="$v.whatsapp.$touch()"
                                  ></v-text-field>
                                  <v-text-field
                                      v-model="responsible"
                                      label="Responsável"
                                      outlined
                                      clearable
                                      dense
                                  ></v-text-field>
                                  <v-select
                                      v-model="situation"
                                      :items="visivel"
                                      item-text="description"
                                      item-value="id"
                                      :error-messages="situationErrors"
                                      label="Visível"
                                      outlined
                                      required
                                      return-object
                                      dense
                                      clearable
                                      @input="$v.situation.$touch()"
                                      @blur="$v.situation.$touch()"
                                  ></v-select>
                                </form>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="save()">
                                  Salvar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog_delete" max-width="290">
                            <v-card class="card-delete">

                              <v-card-text class="text-center">
                                <v-icon>mdi-information-outline</v-icon>
                                <div>Tem certeza que deseja <strong>excluir?</strong>
                                </div>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn depressed
                                       @click="dialog_delete = false">
                                  Não
                                </v-btn>

                                <v-btn depressed :loading="loading" :disabled="loading"
                                       @click="excluir()">
                                  Sim
                                </v-btn>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn dark depressed class="btn-add" v-bind="attrs"
                                     v-on="on"
                                     @click="resetForm(); dialog_add = true">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Cadastrar</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs" v-on="on"
                                  depressed
                                  color="primary"
                                  class="btn-refresh"
                                  :loading="loading_refresh"
                                  :disabled="loading_refresh"
                                  @click="initialize">
                                <v-icon>mdi-refresh</v-icon>
                              </v-btn>
                            </template>
                            <span>Recarregar</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-menu class="menu-list" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link @click="edit(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-file-document-edit-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item link @click="deleteItem(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Excluir</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:no-data>
                  <v-btn :loading="loading_refresh"
                         :disabled="loading_refresh"
                         color="primary" depressed @click="initialize">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-dialog v-model="dialog_info" max-width="800px" scrollable>
      <v-card>
        <v-card-title>
          <span class="card-title">Informação</span>
          <v-spacer></v-spacer>
          <v-btn icon color="blue darken-1"
                 v-on:click="resetForm()"
                 @click="dialog_info = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <Footer/>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, minLength, email} from 'vuelidate/lib/validators';
import Topo from "../../components/Topo";
import Footer from "../../components/Footer";
import {formatBytes, formatDate, parseData} from "../../utils.js";
import {mask} from "vue-the-mask";

export default {
  name: "items",
  components: {Topo, Footer},
  mixins: [validationMixin],
  directives: {mask},
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Setor',
        align: 'start',
        value: 'description',
      },
      {text: 'Telefone', value: 'phone'},
      {text: 'Data de cadastro', value: 'date'},
      {text: 'Visível', value: 'situation', sortable: false},
      {text: 'Ação', value: 'actions', sortable: false},
    ],
    items: [],
    reports: [],
    visivel: [{id: "S", description: "Sim"}, {id: "N", description: "Não"}],
    loading: false,
    loading_file: false,
    loading_situation: false,
    loading_refresh: false,
    dialog_add: false,
    dialog_edit: false,
    dialog_delete: false,
    dialog_upload: false,
    dialog_info: false,
    show: false,
    snackbar: {
      show: false,
      text: "",
      timeout: 7000,
    },
    item: {},
    index: -1,
    situation_index: -1,
    description: '',
    email: '',
    phone: '',
    whatsapp: '',
    responsible: '',
    date: '',
    situation: '',
    image: [],
  }),
  validations: {
    description: {
      required,
      minLength: minLength(5)
    },
    whatsapp: {required, minLength: minLength(14)},
    phone: {required, minLength: minLength(14)},
    email: {required, email},
    situation: {
      required
    },
    image: {
      required
    },
  },
  async created() {
    await this.initialize();
    //await this.statistics();
  },
  computed: {
    descriptionErrors() {
      const errors = []
      if (!this.$v.description.$dirty) return errors
      !this.$v.description.minLength && errors.push('O título deve ter no mínino 5 caracteres.')
      !this.$v.description.required && errors.push('Título é obrigatório.')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Deve ser um e-mail válido.')
      !this.$v.email.required && errors.push('O e-mail é obrigatório.')
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.phone.$dirty) return errors
      !this.$v.phone.minLength && errors.push('O telefone deve ter no mínino 8 caracteres.')
      !this.$v.phone.required && errors.push('O telefone é obrigatório.')
      return errors
    },
    whatsappErrors() {
      const errors = []
      if (!this.$v.whatsapp.$dirty) return errors
      !this.$v.whatsapp.minLength && errors.push('O WhatsApp deve ter no mínino 8 caracteres.')
      !this.$v.whatsapp.required && errors.push('O WhatsApp é obrigatório.')
      return errors
    },
    situationErrors() {
      const errors = []
      if (!this.$v.situation.$dirty) return errors
      !this.$v.situation.required && errors.push('Situação é obrigatório.')
      return errors
    },
    imageErrors() {
      const errors = []
      if (!this.$v.image.$dirty) return errors
      !this.$v.image.required && errors.push('Imagem é obrigatório.')
      return errors
    },
  },
  methods: {
    formatBytes, formatDate,parseData,
    async initialize() {
      this.loading_refresh = true;
      await this.Api().post('/json/departments_list', {
        'token': this.$store.state.token,
      }).then(response => {
        if (response.data.status === "invalid") {
          this.$store.commit('logout');
          this.$router.replace('/login');
        } else {
          this.items = response.data;
          this.loading_refresh = false;
        }
      }).catch(error => {
        this.loading_refresh = false;
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async statistics() {
      await this.Api().post('/json/departments_statistics', {
        'token': this.$store.state.token,
        'disco': parseData(this.$store.state.domain).info_server.body.discoEmails,
      }).then(response => {
        if (response.data.status === "invalid") {
          this.$store.commit('logout');
          this.$router.replace('/login');
        } else {
          this.reports = response.data;
        }
      }).catch(error => {
        this.loading_refresh = false;
        this.snackbar.show = true;
        this.snackbar.text = error;
        this.loading = false;
      });
    },

    async add() {
      this.$v.$touch();

      if (this.$v.description.$anyError
          || this.$v.email.$anyError
          || this.$v.phone.$anyError
          || this.$v.whatsapp.$anyError
          || this.$v.situation.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'description': this.description,
          'email': this.email,
          'phone': this.phone,
          'whatsapp': this.whatsapp,
          'responsible': this.responsible,
          'situation': this.situation,
        };
        this.Api().post('/json/add_departments', data).then(response => {

          switch (response.data.status) {
            case "invalid":
              this.$store.commit('logout');
              this.$router.replace('/login');
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;

              this.items.unshift({
                id: response.data.id,
                description: this.description,
                email: this.email,
                phone: this.phone,
                whatsapp: this.whatsapp,
                responsible: this.responsible,
                date: this.formatDate(new Date().toISOString().substr(0, 10)),
                situation: this.situation
              });
              this.statistics();
              this.loading = false;
              this.dialog_add = false;
              this.resetForm();
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_add = false;

              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);
    },

    async edit(item) {
      this.index = this.items.indexOf(item);
      this.item = Object.assign({}, item);

      this.description = this.item.description;
      this.email = this.item.email;
      this.phone = this.item.phone;
      this.whatsapp = this.item.whatsapp;
      this.responsible = this.item.responsible;
      this.situation = this.item.situation;
      this.dialog_edit = true;
    },

    async save() {
      this.$v.$touch();


      if (this.$v.description.$anyError
          || this.$v.email.$anyError
          || this.$v.phone.$anyError
          || this.$v.whatsapp.$anyError
          || this.$v.situation.$anyError) {
        return;
      }

      this.loading = true;

      setTimeout(() => {
        const data = {
          'item': this.item.id,
          'token': this.$store.state.token,
          'description': this.description,
          'email': this.email,
          'phone': this.phone,
          'whatsapp': this.whatsapp,
          'responsible': this.responsible,
          'situation': this.situation,
        };

        this.Api().post('/json/save_departments', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              this.$store.commit('logout');
              this.$router.replace('/login');
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;

              Object.assign(this.items[this.index], {
                description: this.description,
                email: this.email,
                phone: this.phone,
                whatsapp: this.whatsapp,
                responsible: this.responsible,
                date: this.items[this.index].date,
                situation: this.situation
              });
              this.statistics();
              this.loading = false;
              this.dialog_edit = false;
              this.resetForm();
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_edit = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
        });
      }, 3000);
    },

    async deleteItem(item) {
      this.index = this.items.indexOf(item);
      this.item = Object.assign({}, item);
      this.dialog_delete = true;
    },

    async excluir() {
      this.loading = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': this.item.id,
        };
        this.Api().post('/json/delete_departments', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              this.$store.commit('logout');
              this.$router.replace('/login');
              break;

            case "success":
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.items.splice(this.index, 1);
              this.loading = false;
              this.dialog_delete = false;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading = false;
              this.dialog_delete = false;
              break;
          }

        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.dialog_add = false;
        });
      }, 3000);
    },

    async situation_item(item, r) {
      this.loading_situation = true;

      setTimeout(() => {
        const data = {
          'token': this.$store.state.token,
          'item': item.id,
          'situation': r
        };
        this.Api().post('/json/situation_departments', data).then(response => {
          switch (response.data.status) {
            case "invalid":
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              this.$router.push({path: "/login",}).catch(err => {
                console.log(err)
              });
              break;

            case "success":
              this.index = this.items.findIndex(r => item.id === r.id);
              this.$set(this.items[this.index].situation, 'id', r === 'S' ? 'S' : 'N');

              this.loading_situation = false;
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              break;

            default:
              this.snackbar.show = true;
              this.snackbar.text = response.data.text;
              this.loading_situation = false;
              break;
          }
        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading_situation = false;
        });
      }, 1000);
    },

    resetForm() {
      this.description = '';
      this.email = '';
      this.phone = '';
      this.whatsapp = '';
      this.responsible = '';
      this.situation = '';
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  }
}
</script>

<style lang="scss">
.page-departments{
  .card-quantity{
    .v-icon{
      font-size: 24px!important;
    }
    .title-card{
      font-size: 16px;
      color: var(--cor-principal);
    }
    .value-card{
      font-size: 24px;
      font-weight: bold;
      color: var(--cor-segundaria);
    }
  }
  .card-reports{
    .title-card{
      font-size: 16px;
      color: var(--cor-principal);
    }
  }
}
</style>
