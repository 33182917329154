<template>
  <div id="app">
    <v-app id="inspire">
      <router-view/>
    </v-app>
  </div>
</template>
<script>

export default {

  async created() {
    if (!this.$store.state.domain) {
     // await this.initialize()
    }
  },
  methods: {
    async initialize() {
      await this.Api().post('/json/info_domain', {
        'token': this.$store.state.token,
      }).then(response => {
        if (response.data.status === "invalid") {
          this.$store.commit('logout');
          this.$router.replace('/login');
        } else {
          this.$store.commit('setDomain', JSON.stringify(response.data));
        }
      }).catch(error => {
        console.log(error);
      });
    },
  },
}
</script>
<style lang="scss">
#app {
  .page {
    margin-bottom: 30px;
  }

  .v-application {
    background-color: var(--faint-gray) !important;
  }

  .v-menu__content {
    .v-list-item__icon {
      margin: 12px 20px 12px 0 !important;
    }

    .v-list-item__title {
      font-size: 14px !important;
    }
  }

  .v-dialog {
    .v-card {
      .v-card__title {
        color: var(--white);
        font-size: 18px !important;
        background-color: var(--cor-principal) !important;

        .v-btn {
          color: var(--white) !important;
        }
      }

      .v-card__text {
        padding: 24px 24px 20px !important;
      }

      .v-card__actions {
        padding: 16px 24px !important;

        .v-btn {
          color: var(--white) !important;
          background-color: var(--cor-principal) !important;
        }
      }
    }

    .card-delete {
      .v-icon {
        color: var(--disabled);
        font-size: 70px;
      }
    }

    .card-upload {
      .img-card {
        padding: 0 15px;
        border: 1px solid #cccccc;
        border-radius: 5px;

        .btn-delete-file {
          color: var(--white);
          background-color: var(--danger);
        }
      }

      .information {
        font-size: 13px;

        .original {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .card-actions-upload {
        padding: 0 24px 24px !important;

        .btn-delete-file {
          height: 36px;
          min-width: 36px;
          background-color: var(--danger) !important;
        }

        .btn-capa-img {
          height: 36px;
          min-width: 36px;
          background-color: var(--cor-principal) !important;
        }

        .btn-capa-active {
          height: 36px;
          min-width: 36px;
          background-color: var(--success) !important;
        }

        .btn-download {
          height: 36px;
          min-width: 36px;
          background-color: var(--cor-segundaria) !important;
        }

        .v-icon {
          font-size: 18px !important;
        }
      }
    }
  }

  .card-section {
    .btn-active {
      font-size: 14px !important;
      color: var(--white) !important;
      letter-spacing: initial !important;
      text-transform: initial !important;
      background-color: var(--success) !important;
    }

    .btn-not-active {
      @extend .btn-active;
      background-color: var(--danger) !important;
    }

    .topo-card {
      padding: 12px;

      .search-card {
        .v-input__control {
          min-height: 40px !important;

          .v-input__slot {
            background: var(--light-gray) !important;
          }
        }

      }

      .btn-add {
        margin-left: 5px;
        height: 40px !important;
        font-size: 15px !important;
        color: var(--white) !important;
        letter-spacing: initial !important;
        background-color: var(--cor-segundaria) !important;
      }

      .btn-refresh {
        margin-left: 5px;
        height: 40px !important;
        background-color: var(--cor-principal) !important;
      }

      .btn-add-category {
        @extend .btn-add;
        background-color: var(--cor-principal) !important;
      }
    }
  }

  .embed-responsive {
    iframe {
      width: 100%;
      height: 200px;
    }
  }

  .editor {
    .quillWrapper {

      .ql-toolbar {
        border-radius: 4px 4px 0 0;
        border: 1px solid rgba(0, 0, 0, .38) !important;

        .ql-formats {
          margin: auto !important;

          .ql-list {
            width: 24px !important;
          }

          svg {
            width: 15px !important;
            height: 15px !important;
          }
        }
      }

      .ql-container {
        border-radius: 0 0 4px 4px;
        border-left: 1px solid rgba(0, 0, 0, .38) !important;
        border-right: 1px solid rgba(0, 0, 0, .38) !important;
        border-bottom: 1px solid rgba(0, 0, 0, .38) !important;

        .ql-editor {
          min-height: 100px !important;
        }
      }
    }

    .form--error {
      .ql-toolbar {
        border: 2px solid var(--danger) !important;
      }

      .ql-container {
        border-left: 2px solid var(--danger) !important;
        border-right: 2px solid var(--danger) !important;
        border-bottom: 2px solid var(--danger) !important;
      }
    }

    .error-text {
      margin-left: 15px;
      font-size: 12px;
      color: var(--danger) !important;
      caret-color: var(--danger) !important;
    }
  }
}
</style>
